import {OrderModel, OrderRowModel} from "@/models/order.model";
import {PriceService} from "@/services/PriceService";

// @ts-expect-error window.datalayer is defined
window.dataLayer = window.dataLayer || [];

export class DataLayerService {

    private readonly priceService: PriceService

    constructor() {
        this.priceService = new PriceService()
    }

    private convertToDataLayerRow(orderRows: OrderRowModel[], order: OrderModel) {
        const totalAmount = this.priceService.calculateTotal(order)

        return {
            currency: 'EUR',
            value: totalAmount.toFixed(2).toString(),
            items: orderRows.map(row => ({
                item_name: row.room.name ?? 'unknown',
                item_category: `${row.slot?.startHour ?? 0}:${row.slot?.startMinute ?? 0}`,
                item_category2: `${row.price?.people ?? 'unknown'} Personen`,
                item_list_name: order.location.name,
                price: row.price?.amount.toFixed(2).toString() ?? 'unknown'
            }))
        }
    }

    updateCart(order: OrderModel) {
        const dataLayerPush = {
            event: 'cart_updated',
            ecommerce: this.convertToDataLayerRow(order.orderRows, order)
        }

        console.log('cart_updated', dataLayerPush)

        // @ts-expect-error window.datalayer is defined
        window.dataLayer.push(dataLayerPush);
    }

    checkout(order: OrderModel) {
        const dataLayerPush = {
            event: 'begin_checkout',
            ecommerce: this.convertToDataLayerRow(order.orderRows, order)
        }

        console.log('begin_checkout', dataLayerPush)

        // @ts-expect-error window.datalayer is defined
        window.dataLayer.push(dataLayerPush);
    }
}
