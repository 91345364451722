export default {

    // eslint-disable-next-line
    GetQueryString(params: any) {
        if (!params) {
            return "";
        }
        delete params.Results;
        delete params.Total;
        return "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    },
    getParameterByName(name: string, url?: string) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line no-useless-escape
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
}
