<template>
    <div class="booking-funnel-step booking-funnel-step--01">

        <p class="booking-funnel-step-03__title">
            {{translate("choose-activity")}}<span v-if="location.id === 4">*</span>
        </p>

        <ul class="booking-funnel-step-01__list" v-if="!loading">
            <li class="booking-funnel-step-01__list-item booking-funnel-step-01__list-item--price-type"
                v-for="priceType in orderedPriceTypes" v-bind:key="priceType.name" @click="selectPriceType(priceType)">

                <a class="booking-funnel-step-01__list-location">
                    <span class="booking-funnel-step-01__list-title">
                        {{ priceType.name }}
                    </span>
                </a>
            </li>
        </ul>

        <div v-else class="text-center">
            {{translate('loading')}}
        </div>

        <div class="booking-funnel-step-01__note" v-if="location.id === 4">
            <small>
                {{translate('package-note')}}
            </small>
        </div>
    </div>

</template>

<script>
import BookingService from "@/services/BookingService";
import TranslationService from "@/services/TranslationService";
import _ from "lodash";

export default {
    name: "booking-price-type-select",
    props: [],
    data() {
        return {
            loading: true,
            baseUrl: "/api/v1/booking",
            priceTypes: [],
            filterPriceTypes: null
        }
    },
    computed: {
        orderedPriceTypes() {
            return _.sortBy(this.priceTypes, "seqNr")
        },
        language() {
            return this.$store.state.currentLang
        },
        location() {
            return this.$store.state.location
        }
    },
    created() {
        this.filterPriceTypes = this.$route.query["price-type"];
        this.getPriceTypes();
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "price-type-select", value)
        },
        async getPriceTypes() {
            const resp = await BookingService.GetAsync(this.baseUrl + "/locations/" + this.location.id + "/price-types")
            if (!this.filterPriceTypes) {
                this.priceTypes = resp;
                this.loading = false;
                return;
            }

            this.priceTypes = [];
            resp.forEach((priceType) => {
                if (priceType.Name.toLowerCase() === this.filterPriceTypes.toLowerCase()) {
                    this.priceTypes.push(priceType);
                }
            })

            this.loading = false;
        },
        selectPriceType(priceType) {
            this.$store.dispatch("selectPriceType", priceType);
        }
    }
}


</script>

<style scoped>

</style>
