<template>
    <section class="pagesection pagesection--first">
        <div class="pagesection__container">
            <div class="grid">
                <div class="grid__column grid__column--12">

                    <div class="gift-card__module">
                        <main class="booking-funnel" v-if="locationList.length === 0">

                            <div class="booking-funnel-step booking-funnel-step--04">
                                <h1 class="booking-funnel-step-04__title">
                                    Deze pagina is nog niet in gebruik.
                                </h1>
                            </div>

                        </main>

                        <main class="booking-funnel" v-else>

                            <div class="booking-funnel-giftcard">

                                <div>
                                    <div>
                                        <div class="booking-funnel-giftcard">
                                            <fieldset class="booking-funnel-giftcard">
                                                <div>
                                                    <label class="booking-funnel-giftcard__label" for="FirstName">
                                                        Naam op de cadeaubon:
                                                    </label>
                                                    <input maxlength="60" class="booking-funnel-giftcard__input"
                                                           id="FirstName" type="text" name="FirstName" tabindex="100"
                                                           v-model="gift.name" placeholder="Naam ontvanger">
                                                </div>

                                                <div class="">
                                                    <label class="booking-funnel-giftcard__label" for="Email">
                                                        Naar wie mogen we de cadeaubon toemailen:
                                                    </label>
                                                    <input class="booking-funnel-giftcard__input" id="Email"
                                                           type="email" name="Email" tabindex="102" v-model="gift.email"
                                                           placeholder="E-mail adres">
                                                </div>

                                                <div>
                                                    <label class="booking-funnel-giftcard__label" for="Amount">
                                                        Bedrag in &euro;:
                                                    </label>
                                                    <input class="booking-funnel-giftcard__input" id="Amount"
                                                           type="number" name="Amount" tabindex="103"
                                                           v-model="gift.amount"
                                                           placeholder="Bedrag *">
                                                </div>
                                            </fieldset>
                                        </div>

                                    </div>
                                </div>

                                <div class="booking-funnel-giftcard">

                                    <ul class="booking-funnel-giftcard-ul">
                                        <li class="booking-funnel-giftcard-li" @click="save('ideal')">
                                            <img src="../assets/logo/logo-ideal.svg" alt="Ideal Betalen">
                                        </li>
                                        <li class="booking-funnel-giftcard-li" @click="save('paypal')">
                                            <img src="../assets/logo/logo-paypal.svg" alt="paypal">
                                        </li>
                                        <li class="booking-funnel-giftcard-li" @click="save('creditcard')">
                                            <img src="../assets/logo/logo-credit-card.svg" alt="Creditcard">
                                        </li>
                                    </ul>

                                    <div class="text-danger" v-if="errorMessage">
                                        {{ errorMessage }}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BookingService from "@/services/BookingService";

export default {
    name: "GiftAppView",
    data: () => {
        return {
            baseUrl: "/api/v1/booking",
            loading: true,
            locationList: [],
            errorMessage: null,
            gift: {
                email: "",
                clientVersion: 'iframe',
                amount: 50,
                name: null,
                paymentType: ''
            }
        }
    },
    async created() {
        const res = await BookingService.GetAsync(this.baseUrl + "/locations")
        this.locationList = [];

        res.forEach((l) => {
            if (l.allowGifts) {
                this.locationList.push(l)
            }
        });

        if (this.locationList.length > 0) {
            this.loading = false;
        }
    },
    methods: {
        validateEmail(email) {
            // eslint-disable-next-line
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async save(paymentType) {

            if (!this.validateEmail(this.gift.email)) {
                this.ErrorMessage = "Email is niet geldig.";
                return;
            }
            if (this.gift.amount < 9.99) {
                this.ErrorMessage = "Minimaal bedrag is 10 euro.";
                return;
            }
            this.gift.paymentType = paymentType;
            this.loading = true;

            const res = await BookingService.PostAsync(this.baseUrl + "/gifts", this.gift)

            if (res.redirectUrl) {
                window.top.location.href = res.redirectUrl;
            } else {
                this.loading = false;
                this.errorMessage = res.body.errors.join(",");
            }
        }
    }
};
</script>

<style scoped lang="scss">
.pagesection--first {
    margin-top: 80px
}
</style>
