import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/booking-system/gifts',
        name: 'gift-app',
        component: () => import(/* webpackChunkName: "about" */ '../views/GiftAppView.vue')
    },
    {
        path: '/booking-system/:locationName',
        name: 'booking-app',
        component: () => import(/* webpackChunkName: "about" */ '../views/BookingAppView.vue')
    },
    {
        path: '/feedback/:orderKey',
        name: 'feedback-app',
        component: () => import(/* webpackChunkName: "about" */ '../views/FeedbackAppView.vue')
    },
    {
        path: '/feedback/:orderKey/:score',
        name: 'feedback-score-app',
        component: () => import(/* webpackChunkName: "about" */ '../views/FeedbackAppView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
