const translations: Record<string, Record<string, Record<string, string>>> = {
    'nl': {
        "booking-app-view": {
            "internet-explorer-error": "Helaas, deze module werkt nog niet op Internet Explorer of Edge. Gebruik Firefox, Chrome of Safari. Excuses voor het ongemak."
        },
        "header-component": {
            "room": "Ervaringen",
            "date-time": "Datum & tijd",
            "people-amount": "Aantal personen",
            "customer": "Gegevens & betaling",
        },
        "footer-component": {
            "previous": "Vorige",
            "room-overview": "Overzicht met ervaringen",
            "payment": "Afrekenen",
            "next": "Volgende",
            "pay": "Betalen",
        },
        "summary-component": {
            "overview": "Overzicht",
            "people": "personen",
            "total": "Totale kosten",
            "voucher-2-rooms-error": "(geen korting, meer dan 2 kamers nodig voor voucher)",
            // capital letters are required in key
            "Monday": "Maandag",
            "Tuesday": "Dinsdag",
            "Wednesday": "Woensdag",
            "Thursday": "Donderdag",
            "Friday": "Vrijdag",
            "Saturday": "Zaterdag",
            "Sunday": "Zondag"
        },
        "price-type-select": {
            "choose-activity": "Kies een arrangement",
            "package-note": "* arrangementen met de flight hebben een opslag van 35,-‘"
        },
        "single-room-select": {
            "tsl-warn": "bovengenoemde ervaringen zijn gelijk. Is een tijd bezet bij nummer 1? Boek dan vooral nummer 2 of 3.",
            "loading": "Laden...",
            "book-today": "Vandaag nog spelen",
        },
        "multi-room-select": {
            "loading": "Laden...",
            "not-open-error": "De locatie is niet open op deze dag.",
            "min-days-error": "Dit arrangement kan alleen geboekt worden minimaal __minDaysInAdvance__ dagen voor aanvangsdatum.",
            "no-times-found": "Geen beschikbare tijden gevonden voor __selectedDate__",
            "multiple-days-error": "Deselecteer eerst je kamers voor een andere datum te kiezen",
            // capital letters are required in key
            "Monday": "Maandag",
            "Tuesday": "Dinsdag",
            "Wednesday": "Woensdag",
            "Thursday": "Donderdag",
            "Friday": "Vrijdag",
            "Saturday": "Zaterdag",
            "Sunday": "Zondag"
        },
        "slot-select": {
            "previous": "vorige",
            "next": "volgende",
            "monday": "ma",
            "tuesday": "di",
            "wednesday": "wo",
            "thursday": "do",
            "friday": "vr",
            "saturday": "za",
            "sunday": "zo",
            "time": "Tijd",
            "no-time-found": "Geen beschikbare tijden gevonden",
            "loading": "Laden van beschikbare dagen en tijden...",
            "no-options": "Er zijn geen opties meer beschikbaar voor deze ervaring.",
            "back": "Terug naar overzicht",
            "more-info-call": "Mogelijk zijn er meer plaatsen beschikbaar. Bel hiervoor naar",
            "more-info-mail": "of mail naar",
            // represents month, moment().format('M')
            1: "januari",
            2: "februari",
            3: "maart",
            4: "april",
            5: "mei",
            6: "juni",
            7: "juli",
            8: "augustus",
            9: "september",
            10: "oktober",
            11: "november",
            12: "december",
        },
        "price-select": {
            "room": "Escaperoom",
            "remote": "Remote",
            "people": "personen",
            "remove": "Verwijderen",
            "no-package-deal-error": "Dit arrangement kan niet geboekt worden op deze dag.",
            "add-extra-room": "Extra ervaring toevoegen",
        },
        "customer-select": {
            "first-name": "Voornaam",
            "last-name": "Achternaam",
            "form-xp": "Hoeveel ervaringen heb je al bij Escape World gespeeld?",
            "email": "E-mailadres",
            "phone": "Mobiel nummer",
            "voucher": "Voucher",
            "voucher-placeholder": "Voucher (optioneel)",
            "check-voucher": "Voucher controleren",
            "voucher-not-valid": "Deze code is niet (meer) geldig.",
            "voucher-is-valid": "Voucher is geaccepteerd; de aanbieding is toegepast.",
            "covid-check": "Als mijn groep COVID-19 verschijnselen heeft komen we niet spelen.",
            "terms-check": "Ik ga akkoord met de",
            "terms": "Algemene Voorwaarden",
            "book-extra-room": "Extra ervaring boeken",
            "more-info": "Extra Informatie: ",
            "payment-method": "Hoe wil je graag betalen?",
            "pay-at-location": "Betaal op locatie",
            "voucher-not-verified": "Voucher is niet geverifieerd. Neem contact op met ons als je zeker weet dat deze geldig zou moeten zijn.",
            "validation-accept-terms": "Je moet onze voorwaarden aanvaarden om te boeken.",
            "validation-check-voucher": "Je hebt een voucher ingevuld, maar nog niet gecontroleerd. Daarna kan je pas betalen.",
            "validation-check-invoice": "Je hebt niet alle gegevens ingevuld voor de facturatie.",
            "validation-email-address": "Opgeheven email is niet geldig.",
            "validation-form-xp": "Veld 'Hoeveel kamers heb je al bij Escape World gespeeld' is niet correct ingevuld. (moet een cijfer zijn)",
            "validation-error": "Niet alle verplichte velden zijn ingevuld.",
            "validation-error-form-xp": " (# gespeelde ervaringen)",
            "validation-email": "Geen geldig e-mailadres ingevuld.",

            "is-invoice-requested": "Ik wil graag een factuur ontvangen",
            "invoice-contact": "T.a.v.",
            "invoice-reference": "Kenmerk",
            "invoice-company-name": "Bedrijfsnaam",
            "invoice-company-email": "Facturatie email",
            "invoice-is-company": "Bedrijf (Ja/Nee)",
            "invoice-address": "Adres",
            "invoice-postal-code": "Postcode",
            "invoice-city": "Plaats",
            "invoice-country": "Land",
        },
    },
    'en': {
        "booking-app-view": {
            "internet-explorer-error": "Unfortunately, this module does not work on Internet Explorer or Edge. Please use Firefox, Chrome or Safari."
        },
        "header-component": {
            "room": "Experience(s)",
            "date-time": "Date & time",
            "people-amount": "Number of people",
            "customer": "Info & payment",
        },
        "footer-component": {
            "previous": "Previous",
            "room-overview": "Experience overview",
            "payment": "Checkout",
            "next": "Next",
            "pay": "Pay",
        },
        "summary-component": {
            "overview": "Overview",
            "people": "persons",
            "total": "Total costs",
            "voucher-2-rooms-error": "(no discount, more than two experiences required for voucher)",
            // capital letters are required in key
            "Monday": "Monday",
            "Tuesday": "Tuesday",
            "Wednesday": "Wednesday",
            "Thursday": "Thursday",
            "Friday": "Friday",
            "Saturday": "Saturday",
            "Sunday": "Sunday"
        },
        "price-type-select": {
            "choose-activity": "Choose a package",
            "package-note": "* Packages for 'flight' will have a price increase of 35,-"
        },
        "single-room-select": {
            "tsl-warn": "the experiences above are equal. Is a time slot not available at number 1? Book number 2 or 3.",
            "loading": "Loading...",
            "book-today": "Play today",
        },
        "multi-room-select": {
            "loading": "Loading...",
            "not-open-error": "Venue not open on this day.",
            "min-days-error": "Th package deal can only be booked __minDaysInAdvance__ days minimal before starting date.",
            "no-times-found": "No available time slots found for __selectedDate__",
            "multiple-days-error": "Deselect your experience before choosing a different date",
            // capital letters are required in key
            "Monday": "Monday",
            "Tuesday": "Tuesday",
            "Wednesday": "Wednesday",
            "Thursday": "Thursday",
            "Friday": "Friday",
            "Saturday": "Saturday",
            "Sunday": "Sunday"
        },
        "slot-select": {
            "previous": "previous",
            "next": "next",
            "monday": "mo",
            "tuesday": "tu",
            "wednesday": "we",
            "thursday": "th",
            "friday": "fr",
            "saturday": "sa",
            "sunday": "su",
            "time": "Time",
            "no-time-found": "No time slots found",
            "loading": "Loading...",
            "no-options": "No options available for this experience.",
            "back": "Back to overview",
            "more-info-call": "Possibly there are more seats available. Call",
            "more-info-mail": "or mail ",
            // represents month, moment().format('M')
            1: "january",
            2: "february",
            3: "march",
            4: "april",
            5: "may",
            6: "june",
            7: "july",
            8: "august",
            9: "september",
            10: "october",
            11: "november",
            12: "december",
        },
        "price-select": {
            "room": "Escape room",
            "remote": "Remote",
            "people": "persons",
            "remove": "Delete",
            "no-package-deal-error": "This package deal can't be booked on this day.",
            "add-extra-room": "Add extra experience",
        },
        "customer-select": {
            "first-name": "First name",
            "last-name": "Last name",
            "form-xp": "How many experiences did you play at Escape World?",
            "email": "Emailaddress",
            "phone": "Cell phone number",
            "voucher": "Voucher",
            "voucher-placeholder": "Voucher (optional)",
            "check-voucher": "Validate voucher",
            "voucher-not-valid": "This code isn't valid (anymore).",
            "voucher-is-valid": "Voucher is accepted; the discount is applied.",
            "covid-check": "If my group has COVID-19 symptoms, we will not play.",
            "terms-check": "I agree with the",
            "terms": "General Terms and Conditions",
            "book-extra-room": "Book extra experience",
            "more-info": "Extra info: ",
            "payment-method": "How would you like to pay?",
            "pay-at-location": "Pay at venue",
            "voucher-not-verified": "Voucher is not verified. Please contact us if you are sure it should be valid.",
            "validation-accept-terms": "You must accept our terms and conditions to book an experience.",
            "validation-check-voucher": "You need to validate the voucher before you can pay.",
            "validation-check-invoice": "You need to fill in all information required for an invoice.",
            "validation-email-address": "Invoice email is not valid",
            "validation-form-xp": "Field 'How many rooms did you play at Escape World' isn't used correctly. (make sure it's a number)",
            "validation-error": "Not all required fields have been completed.",
            "validation-error-form-xp": " (# played experiences)",
            "validation-email": "No valid email address entered",

            "is-invoice-requested": "I would like to recieve an invoice",
            "invoice-contact": "Contact",
            "invoice-reference": "Reference",
            "invoice-company-name": "Company name",
            "invoice-company-email": "Invoice email",
            "invoice-is-company": "Company (Yes/No)",
            "invoice-address": "Address",
            "invoice-postal-code": "Zipcode",
            "invoice-city": "City",
            "invoice-country": "Country",
        }
    },
    'de': {
        "booking-app-view": {
            "internet-explorer-error": "Leider funktioniert dieses Modul noch nicht auf Internet Explorer oder Edge. Bitte verwenden Sie Firefox, Chrome oder Safari. Wir entschuldigen uns für die Unannehmlichkeiten."
        },
        "header-component": {
            "room": "Escape Room",
            "date-time": "Datum & Uhrzeit",
            "people-amount": "Anzahl Personen",
            "customer": "Daten & Zahlung"
        },
        "footer-component": {
            "previous": "Zurück",
            "room-overview": "Übersicht Räume",
            "payment": "Bezahlen",
            "next": "Weiter",
            "pay": "Zahlen"
        },
        "summary-component": {
            "overview": "Übersicht",
            "people": "Personen",
            "total": "Gesamtkosten",
            "voucher-2-rooms-error": "(kein Rabatt, mehr als 2 Räume für Gutschein erforderlich)",
            // capital letters are required in key
            "Monday": "Montag",
            "Tuesday": "Dienstag",
            "Wednesday": "Mittwoch",
            "Thursday": "Donnerstag",
            "Friday": "Freitag",
            "Saturday": "Samstag",
            "Sunday": "Sonntag"
        },
        "price-type-select": {
            "choose-activity": "Wählen Sie ein Erlebnis aus",
            "package-note": "* The Flight Escape Room hat einen Aufpreis von 35,- €"
        },
        "single-room-select": {
            "tsl-warn": "Oben genannte Erlebnisse sind gleich. Ist die Zeit bei Nummer 1 belegt? Dann buchen Sie vor allem Nummer 2 oder 3.",
            "loading": "Laden...",
            "book-today": "Heute noch spielen"
        },
        "multi-room-select": {
            "loading": "Laden...",
            "not-open-error": "Der Standort ist an diesem Tag nicht geöffnet.",
            "min-days-error": "Dieses Erlebnis kann nur gebucht werden, wenn es mindestens __minDaysInAdvance__ Tage im Voraus gebucht wird.",
            "no-times-found": "Keine verfügbaren Zeiten gefunden für __selectedDate__",
            "multiple-days-error": "Deaktivieren Sie zunächst Ihre Räume Auswahl, um ein anderes Datum auszuwählen",
            // capital letters are required in key
            "Monday": "Montag",
            "Tuesday": "Dienstag",
            "Wednesday": "Mittwoch",
            "Thursday": "Donnerstag",
            "Friday": "Freitag",
            "Saturday": "Samstag",
            "Sunday": "Sonntag"
        },
        "slot-select": {
            "previous": "vorherige",
            "next": "nächste",
            "monday": "Mo",
            "tuesday": "Di",
            "wednesday": "Mi",
            "thursday": "Do",
            "friday": "Fr",
            "saturday": "Sa",
            "sunday": "So",
            "time": "Zeit",
            "no-time-found": "Keine verfügbaren Zeiten gefunden",
            "loading": "Verfügbarer Tage und Zeiten laden...",
            "no-options": "Es gibt keine weiteren Optionen für dieses Erlebnis.",
            "back": "Zurück zur Übersicht",
            "more-info-call": "Möglicherweise gibt es mehr verfügbare Plätze. Bitte rufen Sie",
            "more-info-mail": "oder senden Sie eine E-Mail an",
            // repräsentiert Monat, moment().format('M')
            1: "Januar",
            2: "Februar",
            3: "März",
            4: "April",
            5: "Mai",
            6: "Juni",
            7: "Juli",
            8: "August",
            9: "September",
            10: "Oktober",
            11: "November",
            12: "Dezember"
        },
        "price-select": {
            "room": "Escape Room",
            "remote": "Remote",
            "people": "Personen",
            "remove": "Entfernen",
            "no-package-deal-error": "Dieses Arrangement kann an diesem Tag nicht gebucht werden.",
            "add-extra-room": "Zusätzliches Erlebnis hinzufügen"
        },
        "customer-select": {
            "first-name": "Vorname",
            "last-name": "Nachname",
            "form-xp": "Wie viele Escape Room Erlebnisse haben Sie bei Escape World gemacht?",
            "email": "E-Mail-Adresse",
            "phone": "Handynummer",
            "voucher": "Gutschein",
            "voucher-placeholder": "Gutschein (optional)",
            "check-voucher": "Gutschein prüfen",
            "voucher-not-valid": "Dieser Voucher ist nicht (mehr) gültig.",
            "voucher-is-valid": "Voucher ist gültig.",
            "covid-check": "Wenn jemand aus meiner Gruppe COVID-19 Symptome hat, werden wir nicht spielen.",
            "terms-check": "Ich akzeptiere die",
            "terms": "Allgemeinen Geschäftsbedingungen",
            "book-extra-room": "Zusätzliches Erlebnis buchen",
            "more-info": "Zusätzliche Informationen: ",
            "payment-method": "Wie möchten Sie bezahlen?",
            "pay-at-location": "Bezahlung vor Ort",
            "voucher-not-verified": "Der Gutschein wurde nicht verifiziert. Wenn Sie sicher sind, dass er gültig sein sollte, kontaktieren Sie uns bitte.",
            "validation-accept-terms": "Sie müssen unsere Bedingungen akzeptieren, um zu buchen.",
            "validation-check-voucher": "Sie haben einen Gutschein eingegeben, aber noch nicht überprüft. Erst danach können Sie bezahlen.",
            "validation-check-invoice": "Sie haben nicht alle Informationen für die Rechnungsstellung eingegeben.",
            "validation-email-address": "Rechnungs-E-Mail ist nicht gültig.",
            "validation-form-xp": "Das Feld 'Wie viele Erlebnisse haben Sie bereits bei Escape World gespielt?' wurde falsch ausgefüllt. (muss eine Zahl sein)",
            "validation-error": "Nicht alle Pflichtfelder wurden ausgefüllt.",
            "validation-error-form-xp": " (# gespielte Erlebnisse)",
            "validation-email": "Keine gültige E-Mail-Adresse angegeben.",
            "is-invoice-requested": "Ich möchte gerne eine Rechnung erhalten",
            "invoice-contact": "An",
            "invoice-reference": "Kennzeichen",
            "invoice-company-name": "Firmenname",
            "invoice-company-email": "Firmen email",
            "invoice-is-company": "Firmen (Ja/Nein)",
            "invoice-address": "Adresse",
            "invoice-postal-code": "Postleitzahl",
            "invoice-city": "Ort",
            "invoice-country": "Land"
        }
    }
}


const TranslationService = {
    translate(lang: string, page: string, value: string) {
        if (!translations[lang][page][value]) {
            return value
        }
        return translations[lang][page][value]
    }
};

export default TranslationService;
