import axios from 'axios';
import Url from "@/helpers/Url";

const BookingService = {

    // eslint-disable-next-line
    async DoRequest(method: any, url: string, data?: object | null, queryParams?: object | null): Promise<any> {
        try {
            const response = await axios({
                method: method,
                url: url + Url.GetQueryString(queryParams),
                data: data
            });
            return response.data;
            // eslint-disable-next-line
        } catch (err: any) {
            throw err.response.data;
        }
    },

    // eslint-disable-next-line
    async GetAsync(url: string, queryParams?: object): Promise<any> {
        return this.DoRequest("get", url, null, queryParams);
    },

    // eslint-disable-next-line
    async PostAsync(url: string, data: object, queryParams?: object): Promise<any> {
        return this.DoRequest("post", url, data, queryParams);
    },

    // eslint-disable-next-line
    async DeleteAsync(url: string, queryParams?: object): Promise<any> {
        return this.DoRequest("delete", url, null, queryParams);
    },

    // eslint-disable-next-line
    async PutAsync(url: string, data: object, queryParams?: object): Promise<any> {
        return this.DoRequest("put", url, data, queryParams);
    }
};

export default BookingService;
