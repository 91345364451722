<template>
    <div>
        <div class="booking-funnel-step booking-funnel-step--03" v-if="!loading">
            <table class="booking-funnel-price__table">

                <thead>
                <tr class="booking-funnel-price__table--header-row">
                    <th class="booking-funnel-price__table--room-name color-yellow">
                        {{ translate("room") }}
                    </th>

                    <th v-for="locPrice in getFilteredHeaders(orderRowPrices[order.orderRows[0].seqNr], order.orderRows)"
                        class="booking-funnel-price__table--header" v-bind:key="locPrice.priceId">
                        <div v-if="locPrice.isRemotePrice">{{ translate("remote") }}</div>
                        <div v-else>
                            <p class="booking-funnel-price__table--header__people">{{ locPrice.price.people }}</p>
                            <p class="booking-funnel-price__table--header__people-title">{{ translate("people") }}</p>
                        </div>
                    </th>
                </tr>

                </thead>

                <tbody v-if="order && order.orderRows">
                <tr v-bind:key="orderRow.slot.id" v-for="(orderRow, idx) in order.orderRows"
                    class="booking-funnel-price__table--room-row">
                    <td class="booking-funnel-price__table--room-title">
                        {{ orderRow.room.name }} ({{ orderRow.slot.startHour }}:{{
                            usePadding(orderRow.slot.startMinute)
                        }})<br>
                        <span class="small text-danger cursor" v-if="order.orderRows.length > 1"
                              @click="removeOrderRow(idx)">{{ translate("remove") }}</span>
                    </td>

                    <td v-if="!getFilteredColumns(orderRowPrices[orderRow.seqNr], orderRow) || getFilteredColumns(orderRowPrices[orderRow.seqNr], orderRow).length === 0"
                        colspan="5"
                        style="text-align: center">
                        {{ translate("no-package-deal-error") }}
                    </td>

                    <td v-for="locationPrice in getFilteredColumns(orderRowPrices[orderRow.seqNr], orderRow)"
                        v-bind:key="locationPrice.id"
                        @click="selectPrice(orderRow, locationPrice)"
                        class="booking-funnel-price__table--price"
                        :class="{'is-active' : locationPrice.isSelected, 'is-disabled' : !isPriceAllowed(orderRow, locationPrice)}">
                        <span v-if="!locationPrice.isNotSelectable && isPriceAllowed(orderRow, locationPrice)">
                            &euro; {{ useFormatPrice(locationPrice.price.amount) }}
                        </span>
                        <span v-else>
                            -
                        </span>

                    </td>
                </tr>
                </tbody>

            </table>

            <div class="booking-funnel-step-03__btn-container">
                <a class="booking-funnel-step-03__btn" href="javascript:;" @click="selectExtraRoom()">
                    <span class="icon icon--remove">&nbsp;</span> {{ translate("add-extra-room") }}
                </a>
            </div>

            <div style="margin-top: 20px"
                 v-if="order && order.orderRows[0] && order.priceType && order.priceType.allowTime">
                <h2 class="booking-funnel-step-03__title">
                    Starttijd arrangement
                </h2>

                <h5 v-if="OpenTime && CloseTime" class="text-center">
                    Arrangement mogelijk van {{ openTime }} tot {{ closeTime }}
                </h5>

                <h3 class="booking-funnel-step-03__sub-title"
                    v-if="order.orderRows[0] && order.orderRows[0].preferredCombinationTime">
                    {{ order.orderRows[0].preferredCombinationTime }}
                </h3>

                <div style="margin-top: 20px">

                    <div class="booking-funnel-price__table--price"
                         @click="selectPriceTime(possibleBeforeStartTime)"
                         v-if="possibleBeforeStartTime"
                         :class="{'is-active' : possibleBeforeStartTime.format('HH:mm') === order.orderRows[0].preferredCombinationTime}">
                        <span style="color:black;">
                            Vooraf ({{ possibleBeforeStartTime.format("HH:mm") }})
                        </span>
                    </div>

                    <div class="booking-funnel-price__table--price">
                        Escaperoom ({{ order.orderRows[0].slot.startHour }}:{{ usePadding(order.orderRows[0].slot.startMinute) }})
                    </div>

                    <div class="booking-funnel-price__table--price"
                         @click="selectPriceTime(possibleAfterStartTime)"
                         v-if="possibleAfterStartTime"
                         :class="{'is-active' : possibleAfterStartTime.format('HH:mm') === order.orderRows[0].preferredCombinationTime}">
                        <span style="color:black;">
                            Achteraf ({{ possibleAfterStartTime.format("HH:mm") }})
                        </span>
                    </div>
                </div>

            </div>


        </div>
    </div>

</template>

<script>
import BookingService from "@/services/BookingService";
import {STEP_NAMES} from "@/store";
import _ from "lodash";
import TranslationService from "@/services/TranslationService";
import moment from "moment-timezone";

var BUFFER_MINUTES = 30;
var ROOM_DURATION = 60;


export default {
    name: "booking-price-select",
    data() {
        return {
            loading: true,
            baseUrl: "/api/v1/booking",
            priceTypeList: [],
            selectedPriceType: null,
            orderRowPrices: {},
            locationPrices: [],

            openTime: "",
            closeTime: "",
            possibleAfterStartTime: "",
            possibleBeforeStartTime: "",
            allowBeforeTime: false,
            allowAfterTime: false,
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        order() {
            return this.$store.state.currentOrder
        },
        location() {
            return this.$store.state.location
        },
        maximumRoomParticipants() {
            return _.maxBy(this.$store.state.currentOrder.orderRows, "room.maximumParticipants").room.maximumParticipants
        },
        minimumRoomParticipants() {
            return _.minBy(this.$store.state.currentOrder.orderRows, "room.minimumParticipants").room.minimumParticipants
        }
    },
    async created() {
        if (this.order.priceType?.default) {
            this.selectedPriceType = this.order.priceType;
        }

        await this.getPriceTypes()
        await this.getLocationPrices()
        this.selectPriceTimeBefore()

    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "price-select", value)
        },
        getFilteredHeaders(prices) {

            return _.sortBy(prices.filter(p => p.priceTypeId === this.order.priceType.id
                && p.price.people >= this.minimumRoomParticipants
                && p.price.people <= this.maximumRoomParticipants
            ), "price.people")
        },
        getFilteredColumns(prices) {
            return _.sortBy(prices.filter(p => p.priceTypeId === this.order.priceType.id
                && p.price.people >= this.minimumRoomParticipants
                && p.price.people <= this.maximumRoomParticipants
            ), "price.people")
        },
        async getPriceTypes() {
            const priceTypes = await BookingService.GetAsync(this.baseUrl + "/locations/" + this.location.id + "/price-types")
            this.priceTypeList = _.sortBy(priceTypes, "seqNr")
        },

        removeOrderRow(index) {
            this.$store.dispatch("removeOrderRowAtIndex", index)
        },
        async getLocationPrices() {
            this.loading = true;

            for (const row of this.order.orderRows) {
                if (this.orderRowPrices[row.seqNr]) {
                    // skip is we already have this room Price
                    continue;
                }
                const url = this.baseUrl + "/locations/" + this.order.location.id + "/prices?date=" + row.date + "&roomId=" + row.room.id;
                this.orderRowPrices[row.seqNr] = await BookingService.GetAsync(url)

                if (row.price) {
                    this.orderRowPrices[row.seqNr].forEach(locPrice => {
                        if (locPrice.price.id === row.price.id) {
                            locPrice.isSelected = true;
                        }
                    })
                }
            }

            this.loading = false;
        },
        getTotalParticipants() {
            let total = 0
            this.order.orderRows.forEach(row => {
                if (row.price) {
                    total += row.price.people
                }
            })
            return total;
        },
        isPriceAllowed(row, price) {
            if (row
                && (
                    price.price.people > row.room.maximumParticipants
                    || price.price.people < row.room.minimumParticipants

                )) {
                return false;
            }

            if (this.order.priceType.default) {
                return true;
            }

            if (price.isRemotePrice && !row.room.isRemoteAvailable) {
                return false;
            }

            let totalParticipants = this.getTotalParticipants();

            if (this.order.priceType
                && this.order.orderRows.length > 1
                && this.order.priceType.minimumParticipants !== 0
                && totalParticipants >= this.order.priceType.minimumParticipants) {
                return true;
            }

            if (this.order.priceType && this.order.priceType.minimumParticipants !== 0
                && price.price.people < this.order.priceType.minimumParticipants
            ) {
                return false;
            }

            return true;
        },
        usePadding(num) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().slice(17, 19);
        },
        useFormatPrice(price) {
            const pStr = price.toString().replace(".", ",");
            if (pStr.indexOf(",") > -1) {
                return pStr;
            }
            return price + ",-"
        },
        selectPrice(orderRow, locPrice) {
            this.orderRowPrices[orderRow.seqNr].forEach(price => {
                price.isSelected = false;
            })
            locPrice.isSelected = true;
            this.$store.dispatch("setPriceForOrderRow", {orderRow, price: locPrice.price})
        },
        selectExtraRoom() {
            this.$store.dispatch("setStep", STEP_NAMES.roomMultiSelect)
        },
        selectPriceTimeBefore() {
            if (!this.order.priceType || !this.order.priceType.allowTime) {
                return;
            }

            let priceType = this.order.priceType;

            const slot = this.order.orderRows[0].slot;

            this.possibleBeforeStartTime = moment(this.order.orderRows[0].date)
                .add(slot.startHour, "h")
                .add(slot.startMinute, "m")
                .subtract(BUFFER_MINUTES, "m")
                .subtract(priceType.durationMinutes, 'm');

            this.possibleAfterStartTime = moment(this.order.orderRows[0].date)
                .add(slot.startHour, "h")
                .add(slot.startMinute, "m")
                .add(ROOM_DURATION, 'm')
                .add(BUFFER_MINUTES, "m");

            this.order.orderRows.forEach((orderRow) => {
                orderRow.preferredCombinationTime = this.possibleBeforeStartTime.format("HH:mm");
            });
        },

        selectPriceTime(time) {
            this.$store.dispatch("setPreferredCombinationTime", time.format("HH:mm"))
        }
    }
}
</script>

<style scoped lang="scss">
.booking-funnel-price__table--room-name {
    width: 300px;
    height: 50px;
    color: #b7b7b7;
    text-align: left;

    font-family: 'Roboto Bold', sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.booking-funnel-price__table--header__people {
    margin: 0
}

.booking-funnel-price__table--header__people-title {
    margin: 0;

    font-family: 'Roboto Bold', sans-serif;
    font-size: 16px;
    font-weight: 200;
}

.booking-funnel-step-03__btn-container {
    width: 100%;

    .booking-funnel-step-03__btn {
        position: relative;
        left: 65%;
        outline: none;
        display: inline-block;
        text-decoration: none;
        color: #ffffff;
        background-color: #930806;
        font-family: 'ArgentCF', sans-serif;
        font-weight: 400;
        border-radius: 50px;
        padding: 8px 30px;
        min-width: 136px;
        margin-top: 15px;
    }

    .icon--remove {
        position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        margin-top: -1px;
        border-radius: 100%;
        background-image: url("../assets/icons/icon-add.png");

        &:after {
            width: 3px;
            height: 13px;
        }
    }
}

.booking-funnel-price__activity-select {
    padding: 0;

    select {
        border: none;
        padding: 12px;
        margin: 0;
        border-radius: 50px;

        background-color: white;
    }
}

.booking-funnel-price__table--activity {
    min-width: 60%;

    .booking-funnel-price__table--header__title {
        display: block;
    }

    .booking-funnel-price__table--header__sub-title {
        display: block;
        font-size: 16px;
        font-weight: 200;
    }
}

.color-yellow {
    color: #edb51e;
}

.color-green {
    color: #2d8f0a;
}

.booking-funnel-price__package-deal__text {
    font-style: italic;
    text-decoration: none;
    padding: 20px;
    margin-top: -30px;
    font-family: 'Roboto Bold', sans-serif;
    font-weight: 200;
    max-width: 65%;
}

.booking-funnel-price__table--empty {
    min-width: 122px;
    padding: 0;
}

.small {
    font-size: 12px;
    text-decoration: underline;
}

.text-danger {
    color: #930806
}

.cursor:hover {
    cursor: pointer;
}

</style>
