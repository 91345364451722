import {createStore} from 'vuex'
import StorageService from "@/services/StorageService";
import {v4 as uuidv4} from 'uuid';
import {OrderModel, OrderRowModel} from "@/models/order.model";
import {OrderRequest} from "@/models/orderRequest.model";
import {InvoiceRequestModel} from "@/models/invoiceRequest.model";
import {DataLayerService} from "@/services/DataLayerService";

const dataLayerService = new DataLayerService()

const STORAGE_KEYS = {
    order: "order-key",
    steps: "steps-key",
    user: "user-key",
    form: "form-key"
}

export const STEP_NAMES = {
    priceTypeSelect: "price-type-select",
    roomSingleSelect: "room-single-select",
    roomMultiSelect: "room-multi-select",
    slotSelect: "slot-select",
    priceSelect: "price-select",
    customerSelect: "customer-select",
}

export interface State {
    currentLang: "nl" | "en" | 'de'
    currentStep: string
    possibleSteps: string[]
    paymentError?: string
    // eslint-disable-next-line
    location: any
    // eslint-disable-next-line
    form: any
    // eslint-disable-next-line
    user: any
    webKeys: string
    currentOrder: OrderModel
    invoiceRequest: InvoiceRequestModel | null
}

export default createStore<State>({
    state: {
        currentLang: "nl",
        currentStep: STEP_NAMES.priceTypeSelect,
        webKeys: '',
        possibleSteps: [
            STEP_NAMES.priceTypeSelect,
            STEP_NAMES.roomSingleSelect,
            STEP_NAMES.roomMultiSelect,
            STEP_NAMES.slotSelect,
            STEP_NAMES.priceSelect,
            STEP_NAMES.customerSelect
        ],
        location: null,
        paymentError: undefined,
        currentOrder: {
            clientVersion: "iframe",
            location: null,
            priceType: null,
            voucherCode: "",
            paymentType: "",
            source: "escape-world",
            voucherAmount: 0,
            voucher: null,
            notes: "",
            orderRows: []
        },
        form: null,
        user: null,
        invoiceRequest: null,
    },
    getters: {
        allowPreviousStep(state) {

            // don't ever go to the package select screen
            if (state.currentStep === STEP_NAMES.roomSingleSelect) {
                return false;
            }

            if (state.currentStep === STEP_NAMES.roomMultiSelect) {
                return false;
            }

            if (state.currentStep === STEP_NAMES.priceTypeSelect) {
                return false;
            }

            if (state.currentStep === STEP_NAMES.slotSelect) {
                return true;
            }

            return true;
        },
        allowNextStep(state) {

            if ((state.currentStep === STEP_NAMES.roomSingleSelect || state.currentStep === STEP_NAMES.roomMultiSelect) && state.currentOrder.orderRows.length === 0) {
                return false;
            }

            if (state.currentStep === STEP_NAMES.customerSelect) {
                return false;
            }

            if (state.currentStep === STEP_NAMES.priceTypeSelect && !state.currentOrder.priceType) {
                console.log("will not allow transition, price type not set")
                return false;
            }
            if (state.currentStep === STEP_NAMES.slotSelect) {
                let allowed = true;
                state.currentOrder.orderRows.forEach(row => {
                    if (!row.slot) {
                        allowed = false;
                    }
                })
                return allowed;
            }

            if (state.currentStep === STEP_NAMES.priceSelect) {

                let allowed = true;
                state.currentOrder.orderRows.forEach(row => {
                    if (!row.price) {
                        allowed = false;
                    }

                    if (state.currentOrder.priceType?.allowTime
                        && !row.preferredCombinationTime) {
                        allowed = false;
                    }
                })
                return allowed;
            }
            return true;
        },
        getOrderModel(state) {
            return state.currentOrder
        },
        getOrderRequest(state) {
            const orderRequest: OrderRequest = {
                locationId: state.currentOrder.location.id,
                voucherCode: state.currentOrder.voucherCode,
                clientVersion: state.currentOrder.clientVersion,
                notes: state.currentOrder.notes,
                paymentType: state.currentOrder.paymentType,
                source: state.currentOrder.source,
                isInvoiceRequested: state.invoiceRequest != null,
                invoiceRequest: state.invoiceRequest,
                user: state.user,
                form: state.form,
                orderRows: [],
                webKeys: state.webKeys
            };

            state.currentOrder.orderRows.forEach(row => {
                orderRequest.orderRows.push({
                    roomId: row.room.id,
                    priceId: row.price.id,
                    date: row.date,
                    preferredCombinationTime: row.preferredCombinationTime,
                    slotId: row.slot.id,
                });
            })

            return orderRequest;
        }
    },
    mutations: {
        setLang(state, lang: "nl" | "en" | 'de') {
            state.currentLang = lang
        },
        setSource(state, source) {
            state.currentOrder.source = source
        },
        resetState(state) {
            state.currentStep = STEP_NAMES.roomSingleSelect;
            state.currentOrder = {
                clientVersion: "iframe",
                location: null,
                priceType: null,
                paymentType: "",
                voucherCode: "",
                source: "escape-world",
                voucherAmount: 0,
                voucher: null,
                notes: "",
                orderRows: []
            };
            state.location = null;
            state.user = null;
            state.form = null;
        },
        setStep(state, step) {
            state.currentStep = step;
        },
        saveWebKeys(state, webKeys) {
            state.webKeys = webKeys;
        },
        addOrderRow(state, orderRow) {
            orderRow.seqNr = uuidv4()
            state.currentOrder.orderRows.push(orderRow)
            dataLayerService.updateCart(state.currentOrder)
        },
        resetOrderRows(state) {
            state.currentOrder.orderRows = []
            dataLayerService.updateCart(state.currentOrder)
        },
        appendRoomAndDateForOrderRow(state, {slot, date}) {
            if (!state.currentOrder.orderRows[0]) {
                throw new Error("There is no room to append slot and date")
            }
            state.currentOrder.orderRows[0].slot = slot;
            state.currentOrder.orderRows[0].date = date;
            dataLayerService.updateCart(state.currentOrder)
        },
        removeOrderRowAtIndex(state, idx) {
            state.currentOrder.orderRows.splice(idx, 1)
            dataLayerService.updateCart(state.currentOrder)
        },
        selectPreferredCombinationTime(state, time) {
            state.currentOrder.orderRows.forEach(row =>
                row.preferredCombinationTime = time
            )
        },
        setPriceForOrderRow(state, orderRowIdxPriceObject) {
            state.currentOrder.orderRows[orderRowIdxPriceObject.index].price = orderRowIdxPriceObject.price
            dataLayerService.updateCart(state.currentOrder)
        },
        setLocation(state, location) {
            state.location = location
            state.currentOrder.location = location
        },
        setPriceType(state, priceType) {
            state.currentOrder.orderRows.forEach(row => {
                row.preferredCombinationTime = undefined
            })

            state.currentOrder.priceType = priceType
        },
        setOrder(state, order) {
            state.currentOrder = order
        },
        setUser(state, user) {
            state.user = user
        },
        setInvoiceInformation(state: State, invoiceRequest: InvoiceRequestModel | null) {
            state.invoiceRequest = invoiceRequest
        },
        setPaymentMethod(state, paymentMethod) {
            state.currentOrder.paymentType = paymentMethod
        },
        setForm(state, form) {
            state.form = form
        },
        setVoucher(state, voucher) {
            state.currentOrder.voucher = voucher;
            state.currentOrder.voucherCode = voucher.voucherCode;
        },
        setPaymentError(state, errorMessage) {
            state.paymentError = errorMessage
        },
        setPreferredCombinationTime(state, time) {
            state.currentOrder.orderRows.forEach(row => {
                row.preferredCombinationTime = time
            })
        }
    },
    actions: {
        setLang({commit}, lang: "nl" | "en" | "de") {
            commit("setLang", lang)
        },
        setSource({commit}, source: "locked" | "escape-world") {
            commit("setSource", source)
        },
        setStep({commit, dispatch}, step) {
            commit("setStep", step)
            dispatch("saveOrder");
        },
        saveWebKeys({commit}, webKeys) {
            commit("saveWebKeys", webKeys)
        },
        nextStep({dispatch, state, getters}) {
            if (!getters.allowNextStep) {
                return;
            }

            const currentStepIdx = state.possibleSteps.indexOf(state.currentStep)
            if (currentStepIdx === state.possibleSteps.length) {
                return;
            }
            let nextStep = state.possibleSteps[currentStepIdx + 1]

            if (state.currentStep === STEP_NAMES.roomSingleSelect) {
                nextStep = STEP_NAMES.slotSelect
            }

            if (state.currentStep === STEP_NAMES.roomMultiSelect) {
                nextStep = STEP_NAMES.priceSelect
            }

            dispatch("setStep", nextStep)
        },
        prevStep({dispatch, commit, getters, state}) {
            if (!getters.allowPreviousStep) {
                return;
            }
            const currentStepIdx = state.possibleSteps.indexOf(state.currentStep)
            if (currentStepIdx === 0) {
                return;
            }
            let prevStep = state.possibleSteps[currentStepIdx - 1]

            if (state.currentStep === STEP_NAMES.slotSelect) {
                prevStep = STEP_NAMES.roomSingleSelect
                commit("resetOrderRows")
            }

            if (state.currentStep === STEP_NAMES.priceSelect) {
                if (state.currentOrder.orderRows.length === 1) {
                    prevStep = STEP_NAMES.slotSelect
                } else {
                    prevStep = STEP_NAMES.roomMultiSelect
                }
            }

            dispatch("setStep", prevStep)
        },
        setLocation({commit}, location) {
            commit("setLocation", location)
        },
        selectPriceType({commit, dispatch}, priceType) {
            commit("setPriceType", priceType)
            dispatch("nextStep")
        },
        changePriceType({state, commit}, priceType) {
            state.currentOrder.orderRows.forEach((row, idx) => {
                commit("setPriceForOrderRow", {index: idx, price: null})
            })

            commit("setPriceType", priceType)
        },
        selectPreferredCombinationTime({commit}, time) {
            commit("selectPreferredCombinationTime", time)
        },
        selectRoom({commit, dispatch}, room) {
            const orderRow: OrderRowModel = {
                seqNr: -1,
                room: room,
                date: undefined,
                slot: null,
                price: null,
            }
            commit("addOrderRow", orderRow)
            dispatch("nextStep")
        },
        setPaymentMethod({commit}, paymentMethod: string) {
            commit("setPaymentMethod", paymentMethod)
        },
        addOrderRow({commit}, orderRow) {
            commit("addOrderRow", orderRow)
        },
        appendRoomAndDateForOrderRow({commit}, partialOrderRow) {
            commit("appendRoomAndDateForOrderRow", partialOrderRow)
        },
        setPriceForOrderRow({state, commit}, orderRowPriceObject) {
            const orderRowIndex = state.currentOrder.orderRows.findIndex(row => row.seqNr === orderRowPriceObject.orderRow.seqNr);
            commit("setPriceForOrderRow", {index: orderRowIndex, price: orderRowPriceObject.price})
        },
        removeOrderRowAtIndex({commit}, idx) {
            commit("removeOrderRowAtIndex", idx)
        },
        setUser({commit}, user) {
            commit("setUser", user)
        },
        setInvoiceInformation({commit}, invoiceRequest) {
            commit("setInvoiceInformation", invoiceRequest)
        },
        removeInvoiceInformation({commit}) {
            commit("setInvoiceInformation", null)
        },
        setForm({commit}, form) {
            commit("setForm", form)
        },
        setVoucher({commit}, voucher) {
            commit("setVoucher", voucher)
        },
        saveOrder({state}) {
            StorageService.setInStorage(STORAGE_KEYS.steps, state.currentStep, 15)
            StorageService.setInStorage(STORAGE_KEYS.order, state.currentOrder, 15)
            StorageService.setInStorage(STORAGE_KEYS.user, state.user, 15)
            StorageService.setInStorage(STORAGE_KEYS.form, state.form, 15)
        },
        rehydrateOrder({commit}) {
            const order = StorageService.getFromStorage(STORAGE_KEYS.order);
            if (order) {
                commit("setOrder", order)
            }
            commit("setUser", StorageService.getFromStorage(STORAGE_KEYS.user))
            commit("setForm", StorageService.getFromStorage(STORAGE_KEYS.form))
            commit("setStep", StorageService.getFromStorage(STORAGE_KEYS.steps))
        },
        resetStorage({commit}) {
            StorageService.resetStorage()
            commit("resetState")
        },
        setPaymentError({commit}, errorMessage) {
            commit("setPaymentError", errorMessage)
        },
        setPreferredCombinationTime({commit}, time) {
            commit("setPreferredCombinationTime", time)
        }
    },
    modules: {}
})
