<template>
    <div class="booking-funnel-step booking-funnel-step--01">

        <div class="booking-funnel-step-01__btn-container">
            <a class="booking-funnel-step-01__btn" @click="toggleMultiRoomSelect()">
                {{translate("book-today")}}
            </a>
        </div>

        <ul class="booking-funnel-step-01__list" v-if="!loading">

            <li class="booking-funnel-step-01__list-item" v-for="room in orderedRooms" v-bind:key="room.name">

                <a class="booking-funnel-step-01__list-location" href="javascript:;" @click="selectRoom(room)">
                    <img class="booking-funnel-step-01__list-image" :src="'/images/room/' + room.image + '.png'"
                         alt="Kamer afbeelding">
                    <span class="booking-funnel-step-01__list-title">
                    {{ room.name }}
                </span>
                </a>

            </li>

        </ul>

        <div class="text-center" v-else>
            {{translate("loading")}}
        </div>

        <div v-if="location.Id === 5" class="text-center">
            {{translate("tsl-warn")}}
        </div>
    </div>

</template>

<script>
import _ from 'lodash';
import {STEP_NAMES} from "@/store";
import TranslationService from "@/services/TranslationService";

export default {
    name: "booking-single-room-select",
    data() {
        return {
            loading: false
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        location() {
            return this.$store.state.location
        },
        orderedRooms() {
            return _.sortBy(this.location.rooms, "order");
        }
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "single-room-select", value)
        },
        toggleMultiRoomSelect() {
            this.$store.dispatch("setStep", STEP_NAMES.roomMultiSelect)
        },
        selectRoom(room) {
            this.$store.dispatch("selectRoom", room)
        }
    }
}
</script>

<style scoped>

</style>
