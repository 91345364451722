<template>
    <div class="booking-funnel__header">
        <ul class="booking-funnel__steps-list">

            <li class="booking-funnel__steps-list-item"
                :class="{'is-active' : stepStarted('room'), 'is-finished' : stepFinished('room')}">
                <i class="booking-funnel__steps-list-icon"></i>
                <span class="booking-funnel__steps-list-title">{{translate("room")}}</span>
            </li>

            <li class="booking-funnel__steps-list-item"
                :class="{'is-active' : stepStarted('slot'), 'is-finished' : stepFinished('slot')}">
                <i class="booking-funnel__steps-list-icon"></i>
                <span class="booking-funnel__steps-list-title">{{translate("date-time")}}</span>
            </li>

            <li class="booking-funnel__steps-list-item"
                :class="{'is-active' : stepStarted('price'), 'is-finished' : stepFinished('price')}">
                <i class="booking-funnel__steps-list-icon"></i>
                <span class="booking-funnel__steps-list-title">{{translate("people-amount")}}</span>
            </li>

            <li class="booking-funnel__steps-list-item"
                :class="{'is-active' : stepStarted('customer')}">
                <i class="booking-funnel__steps-list-icon"></i>
                <span class="booking-funnel__steps-list-title">{{translate("customer")}}</span>
            </li>


        </ul>
    </div>
</template>

<script>
import {STEP_NAMES} from "@/store";
import TranslationService from "@/services/TranslationService";

export default {
    name: "booking-header-component",
    data() {
        return {
            STEP_NAMES: STEP_NAMES,
            startMap: {
                'room': [STEP_NAMES.priceTypeSelect, STEP_NAMES.roomSingleSelect, STEP_NAMES.roomMultiSelect],
                'slot': [STEP_NAMES.slotSelect],
                'price': [STEP_NAMES.priceSelect],
                'customer': [STEP_NAMES.customerSelect]
            },
            finishMap: {
                'room': [STEP_NAMES.slotSelect, STEP_NAMES.priceSelect, STEP_NAMES.customerSelect],
                'slot': [STEP_NAMES.priceSelect, STEP_NAMES.customerSelect ],
                'price': [ STEP_NAMES.customerSelect],
                'customer': []
            },
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        currentStep() {
            return this.$store.state.currentStep
        }
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "header-component", value)
        },
        stepStarted(stepName) {
            return this.startMap[stepName].indexOf(this.currentStep) > -1
        },
        stepFinished(stepName) {
            return this.finishMap[stepName].indexOf(this.currentStep) > -1
        },
    }

}
</script>

<style scoped>

</style>
