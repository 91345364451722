<template>
    <div class="booking-funnel-step booking-funnel-step--02">
        <div class="booking-funnel-step-02__datepicker" v-if="!loading">
            <table>
                <thead>
                <tr>
                    <th colspan="7">
                    <span class="booking-funnel-step-02__datepicker-btnprev" @click="prevMonth()">
                        <span class="icon"></span>
                        <span>{{translate("previous")}}</span>
                    </span>
                        <span>
                        {{ useGetMonth(selectedCalenderDate) }}
                    </span>
                        <span class="booking-funnel-step-02__datepicker-btnnext" @click="nextMonth()">
                        <span class="icon"></span>
                        <span>{{translate("next")}}</span>
                    </span>
                    </th>
                </tr>
                <tr>
                    <th>
                        {{translate("monday")}}
                    </th>
                    <th>
                        {{translate("tuesday")}}
                    </th>
                    <th>
                        {{translate("wednesday")}}
                    </th>
                    <th>
                        {{translate("thursday")}}
                    </th>
                    <th>
                        {{translate("friday")}}
                    </th>
                    <th>
                        {{translate("saturday")}}
                    </th>
                    <th>
                        {{translate("sunday")}}
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="week in calendar" v-bind:key="week.value.id">

                    <td :class="{'is-inactive' : (day.isOld || day.isClosed || day.isFull), 'is-active' : day.isSelected}"
                        v-for="day in week.value.days" v-bind:key="day.day" :data-date="day.date">

                    <span v-if="!day.empty && day.isOld || day.isClosed  || day.isFull">
                        {{ useGetDay(day.date) }}
                    </span>

                    <span v-if="!day.empty && !day.isClosed && !day.isFull && !day.isOld" @click="selectDate(day)">
                        {{ useGetDay(day.date) }}
                    </span>

                    </td>

                </tr>

                </tbody>

            </table>
        </div>

        <div class="booking-funnel-step-02__timepicker" v-if="!loadingSlots">
            <h2 class="booking-funnel-step-02__title">
                {{translate("time")}}
            </h2>

            <ul class="booking-funnel-step-02__list">

                <template v-for="slot of orderedSlots" v-bind:key="slot.id">
                    <li class="booking-funnel-step-02__list-item" :class="{ 'is-active' : slot.isSelected}"
                        v-if="!slot.isOrder && slot.isAvailable" @click="selectSlot(slot)"
                        @dblclick="selectSlot(slot, true)" :data-time="slot.startHour + ':' + usePadding(slot.startMinute)">
                        <a>
                            {{ slot.startHour }}:{{ usePadding(slot.startMinute) }}
                        </a>
                    </li>

                    <li class="booking-funnel-step-02__list-item is-inactive" v-if="slot.isOrder" :data-time="slot.startHour + ':' + usePadding(slot.startMinute)">
                        <a href="javascript:;" style="color: #edb51e">
                            {{ slot.startHour }}:{{ usePadding(slot.startMinute) }}
                        </a>
                    </li>

                    <li class="booking-funnel-step-02__list-item is-inactive" v-if="!slot.isAvailable" :data-time="slot.startHour + ':' + usePadding(slot.startMinute)">
                        <a href="javascript:;">
                            {{ slot.startHour }}:{{ usePadding(slot.startMinute) }}
                        </a>
                    </li>

                </template>

                <li v-if="orderedSlots.length === 0 && selectedCalenderDate != null"
                    class="booking-funnel-step-02__list-item">
                    <a>
                        {{translate("no-time-found")}}
                    </a>
                </li>

            </ul>
        </div>

        <div class="booking-funnel-step-02__timepicker" v-else>
            <h2 class="booking-funnel-step-02__title">
                {{translate("time")}}
            </h2>

            <div>
                {{translate("laoding")}}
            </div>
        </div>

        <div v-if="dateNotFound">
            <p class="text-center" style="display: block;">
                {{translate("no-options")}}<br/>
                <a style="text-decoration: underline" @click="resetRoom()">{{translate("back")}}</a>
            </p>
        </div>

        <div v-if="showToday" style="padding: 20px;">
            <p style="display: block">
                {{translate("more-info-call")}}

                <a style="text-decoration: underline" href="'tel:' + location.PhoneNumber">
                    {{ location.phoneNumber }}
                </a>
                {{translate("more-info-mail")}}
                <a style="text-decoration: underline" :href="'mailto:' + location.contactEmail">
                    {{ order.Location.ContactEmail }}
                </a>
            </p>
        </div>

    </div>

</template>

<script>
import moment from "moment-timezone";
import BookingService from "@/services/BookingService";
import _ from "lodash";
import TranslationService from "@/services/TranslationService";

export default {
    name: "booking-slot-select",
    data() {
        return {
            baseUrl: "/api/v1/booking",
            loading: false,
            dateNotFound: false,
            showToday: false,
            internalCalendar: null,
            loadingSlots: false,
            slots: [],
            selectedCalenderDate: moment().tz("Europe/Amsterdam").format("YYYY-MM-DD"),
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        calendar() {
            if (!this.internalCalendar) {
                return [];
            }

            const result = [];
            let firstWeek = this.internalCalendar.weeks[Object.keys(this.internalCalendar.weeks)[0]].days;

            for (let x = firstWeek.length; x < 7; x++) {
                firstWeek.unshift({
                    Empty: true
                });
            }

            for (var y in this.internalCalendar.weeks) {
                result.push({
                    key: y,
                    value: this.internalCalendar.weeks[y]
                });
            }

            return result;
        },
        order() {
            return this.$store.state.currentOrder
        },
        location() {
            return this.$store.state.location
        },
        orderedSlots() {
            return _.sortBy(this.slots, "startHour")
        }
    },
    async created() {
        await this.getCalendar();
    },
    methods: {
        resetRoom() {
            this.$store.dispatch("prevStep")
        },
        translate(value) {
            return TranslationService.translate(this.language, "slot-select", value)
        },
        usePadding(num) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().slice(17, 19);
        },
        useGetDay(date) {
            if (date) {
                return moment(date).format("D");
            }
        },
        useGetMonth(date) {
            return this.translate(moment(date).format("M"))
        },
        async nextMonth() {
            this.selectedCalenderDate = moment(this.selectedCalenderDate).add(1, 'M');
            await this.getCalendar();
        },
        async prevMonth() {
            this.selectedCalenderDate = moment(this.selectedCalenderDate).add(-1, 'M');
            await this.getCalendar();
        },
        async getCalendar() {
            this.loading = true;

            if (!this.selectedCalenderDate) {
                this.selectedCalenderDate = moment().tz("Europe/Amsterdam");
            }
            if (this.order.orderRows.length === 0) {
                return;
            }

            const orderRow = this.order.orderRows[0]
            let url = this.baseUrl + "/locations/" + this.location.id + "/rooms/" +
                orderRow.room.id + "/calendar/" + moment(this.selectedCalenderDate).tz("Europe/Amsterdam").format("YYYY-MM-DD");

            if (this.order.priceType) {
                url += "?priceTypeId=" + this.order.priceType.id;
            }

            this.internalCalendar = null;

            this.internalCalendar = await BookingService.GetAsync(url);

            if (orderRow.date) {
                await this.selectSlotForOrderRow(orderRow);
            } else {
                await this.selectFirstAvailableDate();
            }

            this.loading = false;
        },
        async selectSlotForOrderRow(orderRow) {
            let found = null;
            for (const [, week] of Object.entries(this.internalCalendar.weeks)) {
                week.days.forEach(d => {
                    if (moment(d.date).format("YYYY-MM-DD") === orderRow.date) {
                        found = d;
                    }
                })
            }

            if (found) {
                await this.selectDate(found);
                this.slots.forEach(slot => {
                    if (orderRow.slot.id === slot.id) {
                        this.selectSlot(slot)
                    }
                })
            }
        },
        async selectFirstAvailableDate() {
            let found = null;

            for (const [, week] of Object.entries(this.internalCalendar.weeks)) {
                week.days.forEach(day => {
                    if (!day.isClosed && !day.isFull && !day.isOld && !found) {
                        found = day;
                    }
                })
            }

            if (found) {
                await this.selectDate(found);
                return;
            }

            // only check next month
            if (!found && moment().tz("Europe/Amsterdam").month() === moment(this.selectedCalenderDate).tz("Europe/Amsterdam").month()) {
                await this.nextMonth()
            }
        },
        async selectDate(day) {
            this.unSelectAllDays();
            day.isSelected = true;
            await this.loadSlots(day);
        },
        unSelectAllDays() {
            for (const [, week] of Object.entries(this.internalCalendar.weeks)) {
                week.days.forEach(day => {
                    day.isSelected = false;
                })
            }
        },
        async loadSlots(day) {
            if (this.order.orderRows.length === 0) {
                return;
            }
            this.loadingSlots = true;

            let url = this.baseUrl + "/locations/" + this.location.id +
                "/rooms/" + this.order.orderRows[0].room.id + "/slot/" +
                day.date.substring(0, 10);

            if (this.order.priceType) {
                url += "?PriceTypeId=" + this.order.priceType.id;
            }

            this.slots = await BookingService.GetAsync(url);
            this.selectedCalenderDate = moment(day.date).format("YYYY-MM-DD"),
                this.loadingSlots = false;
        },
        selectSlot(slot, doubleClick) {
            this.slots.forEach(s => s.isSelected = false);
            slot.isSelected = true;
            this.$store.dispatch("appendRoomAndDateForOrderRow", {slot, date: this.selectedCalenderDate})
            if (doubleClick) {
                this.$store.dispatch("nextStep")
            }
        },
    }
}
</script>

<style scoped>
</style>
