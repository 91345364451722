<template>
    <div class="booking-funnel__footer">
        <nav class="booking-funnel__nav">
            <ul class="booking-funnel__nav-list">
                <li v-if="allowPrevStep" class="booking-funnel__nav-list-item">
                    <a class="booking-funnel__nav-btn booking-funnel__nav-btn--prev" href="javascript:;"
                       @click="prevStep()">
                        <span class="icon"></span>
                        {{ translate("previous") }}
                    </a>
                </li>
                <li v-else class="booking-funnel__nav-list-item">
                    &nbsp;
                </li>

                <li v-if="currentStep === 'room-multi-select' && order.orderRows.length === 0"
                    class="booking-funnel__nav-list-item" @click="goToRoomSelect">
                    <a class="booking-funnel__nav-btn booking-funnel__nav-btn--next">
                    <span>
                        {{ translate("room-overview") }}
                    </span>
                    </a>
                </li>

                <li class="booking-funnel__nav-list-item" @click="nextStep()" v-if="allowNextStep">
                    <a class="booking-funnel__nav-btn booking-funnel__nav-btn--next"
                       :id="isNextStepFinal ? 'paymentbutton': ''" href="javascript:;">
                    <span v-if="isNextStepFinal">
                        {{ translate("payment") }}
                    </span>

                        <span v-else>
                        {{ translate("next") }}
                    </span>
                        <span class="icon"></span>
                    </a>
                </li>

                <li class="booking-funnel__nav-list-item" @click="nextStep()" v-if="isPayment">
                    <a class="booking-funnel__nav-btn booking-funnel__nav-btn--next" id="checkoutbutton"
                       href="javascript:;">
                    <span v-if="isPayment && !loadingPayment" @click="createOrder">
                        {{ translate("pay") }}
                    </span>
                        <span v-if="isPayment && loadingPayment">
                        {{ translate("loading") }}
                    </span>
                    </a>
                </li>

            </ul>
        </nav>
    </div>
</template>

<script>
import {STEP_NAMES} from "@/store";
import BookingService from "@/services/BookingService";
import TranslationService from "@/services/TranslationService";
import {DataLayerService} from "@/services/DataLayerService";

const dataLayerService = new DataLayerService()

export default {
    name: "booking-footer-component",
    data: () => {
        return {
            baseUrl: "/api/v1/booking",
            loadingPayment: false
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        isNextStepFinal() {
            return this.currentStep === STEP_NAMES.priceSelect
        },
        isPayment() {
            return this.order.paymentType
        },
        currentStep() {
            return this.$store.state.currentStep
        },
        order() {
            return this.$store.state.currentOrder
        },
        allowNextStep() {
            return this.$store.getters.allowNextStep
        },
        allowPrevStep() {
            return this.$store.getters.allowPreviousStep
        }
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "footer-component", value)
        },
        goToRoomSelect() {
            this.$store.dispatch("setStep", STEP_NAMES.roomSingleSelect)
        },
        nextStep() {
            this.$store.dispatch("nextStep")
        },
        prevStep() {
            this.$store.dispatch("prevStep")
        },
        async createOrder() {
            this.$store.dispatch("setPaymentError", undefined);
            this.loadingPayment = true;
            const orderRequest = this.$store.getters.getOrderRequest

            try {
                dataLayerService.checkout(this.$store.getters.getOrderModel)
                const paymentResponse = await BookingService.PostAsync(`${this.baseUrl}/payments`, {
                    paymentType: orderRequest.paymentType,
                    orderRequest
                })

                console.log(paymentResponse)

                this.$store.dispatch("resetStorage");

                if (paymentResponse.fullVoucherDiscount) {
                    window.top.location.href = paymentResponse.successPageRedirectUrl;
                    return;
                }

                if (paymentResponse.redirectUrl) {
                    window.top.location.href = paymentResponse.redirectUrl;
                    return;
                }

                console.error(paymentResponse.errors.join(", "))
                this.$store.dispatch("setPaymentError", "Er ging iets mis. De reservering werd aangemaakt en vervalt over pas over 15 minuten, probeer de boeking later opnieuw of neem contact op met de locatie.");
            } catch (e) {
                console.log(e)
                this.loadingPayment = false;
                if (e.Errors) {
                    this.$store.dispatch("setPaymentError", `Er ging iets mis. Foutmelding: ${e.Errors.join(", ")}`);
                    return
                }
                this.$store.dispatch("setPaymentError", "Er ging iets mis. Deze kamer is intussen al gereserveerd. Neem contact op met de locatie.");
            }
        },
    }
}
</script>

<style scoped lang="scss">

.booking-funnel__nav-btn {
    background-color: #930806;
    color: #ffffff;
    text-decoration: none;
    font-family: 'ArgentCF', sans-serif;;
    font-weight: 400;
    border-radius: 50px;
    padding: 8px 24px;
    min-width: 136px;
    position: relative;
    top: -25px
}

.booking-funnel__nav-btn--prev {

    .icon {
        display: inline-block;
        width: 12px;
        height: 11px;
        line-height: 1;
        background-image: url("../assets/icons/icon-arrow-left-sm.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 11px;
        margin-right: 3px;
    }

    &:hover {
        .icon {
            background-image: url("../assets/icons/icon-arrow-left-lg.png");
        }
    }
}

.booking-funnel__nav-btn--next {

    background-color: #2d8f0a;
    padding: 10px 28px;

    .icon {
        display: inline-block;
        width: 12px;
        height: 11px;
        line-height: 1;
        background-image: url("../assets/icons/icon-arrow-right-sm.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 11px;
        margin-left: 3px;
    }

    &:hover {
        .icon {
            background-image: url("../assets/icons/icon-arrow-right-lg.png");
        }
    }
}
</style>
