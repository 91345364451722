<template>
    <div class="booking" v-if="!isIE">
        <main class="booking-funnel" v-if="!loading">
            <booking-header-component></booking-header-component>

            <div v-if="errorMessage" class="text-center" style="color:red;">
                {{ errorMessage }}
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.priceTypeSelect">
                <booking-price-type-select></booking-price-type-select>
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.roomSingleSelect">
                <booking-single-room-select></booking-single-room-select>
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.roomMultiSelect">
                <booking-multi-room-select></booking-multi-room-select>
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.slotSelect">
                <booking-slot-select></booking-slot-select>
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.priceSelect">
                <booking-price-select></booking-price-select>
            </div>

            <div class="booking-funnel__section" v-if="currentStep === STEP_NAMES.customerSelect">
                <booking-customer-select></booking-customer-select>
            </div>

            <booking-footer-component></booking-footer-component>
        </main>

        <aside class="booking-summary">
            <booking-summary-component></booking-summary-component>
        </aside>
    </div>
    <div class="booking" v-else>
        <main class="booking-funnel">
            <p>
                {{translate("internet-explorer-error")}}
            </p>
        </main>
    </div>

</template>

<script>
import BookingHeaderComponent from "@/components/BookingHeaderComponent";
import BookingFooterComponent from "@/components/BookingFooterComponent";
import BookingSummaryComponent from "@/components/BookingSummaryComponent";
import BookingPriceTypeSelect from "@/components/BookingPriceTypeSelect";
import BookingService from "@/services/BookingService";
import BookingSingleRoomSelect from "@/components/BookingSingleRoomSelect";
import BookingMultiRoomSelect from "@/components/BookingMultiRoomSelect";
import BookingSlotSelect from "@/components/BookingSlotSelect";
import BookingPriceSelect from "@/components/BookingPriceSelect";
import {STEP_NAMES} from "@/store";
import BookingCustomerSelect from "@/components/BookingCustomerSelect";
import TranslationService from "@/services/TranslationService";

const QUERY_ROOM_TAG = 'room-tag'
const QUERY_MULTI_SELECT = 'multi-select'
const QUERY_PRICE_TYPE = 'package-type'
const QUERY_SHOW_PRICE_TYPES = 'show-package-types'
const QUERY_LANGUAGE = 'lang'
const QUERY_SOURCE = 'source'
const DEFAULT_LANG = 'nl'
const QUERY_WEB_KEYS = 'web-keys'


export default {
    name: "BookingAppView",
    components: {
        BookingCustomerSelect,
        BookingPriceSelect,
        BookingSlotSelect,
        BookingMultiRoomSelect,
        BookingSingleRoomSelect,
        BookingPriceTypeSelect,
        BookingSummaryComponent,
        BookingFooterComponent,
        BookingHeaderComponent
    },
    data() {
        return {
            STEP_NAMES: STEP_NAMES,
            baseUrl: "/api/v1/booking",
            loading: true,
            errorMessage: null
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        order() {
            return this.$store.state.currentOrder
        },
        currentStep() {
            return this.$store.state.currentStep
        },
        location() {
            return this.$store.state.location
        },
        isIE() {
            const ua = navigator.userAgent;
            return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
        }
    },
    async created() {
        this.$store.dispatch("rehydrateOrder");

        const location = await BookingService.GetAsync(this.baseUrl + "/locations/" + this.$route.params["locationName"])

        // if the localStorage is set for a different location, we need to clear everything
        if (this.order && this.order.location?.id !== location.id) {
            this.$store.dispatch("resetStorage");
        }

        this.$store.dispatch("setLocation", location)
        await this.setUpLang();
        await this.setUpSource();

        if (await this.setUpPriceTypes()) {
            this.loading = false;
            return;
        }

        // only setup multi-select if there is no price-type selection passed
        if (this.setUpMultiSelect()) {
            this.loading = false;
            return;
        }

        // only set up a room if no multi-select tag has been passed
        this.setUpRoom();
        this.loading = false;
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "booking-app-view", value)
        },
        async setUpLang() {
            if (this.$route.query[QUERY_LANGUAGE]) {
                this.$store.dispatch("setLang", this.$route.query[QUERY_LANGUAGE])
            } else {
                this.$store.dispatch("setLang", DEFAULT_LANG)
            }
        },
        async setUpPriceTypes() {
            if (this.$route.query[QUERY_WEB_KEYS]) {
                const webKeys = this.$route.query[QUERY_WEB_KEYS]
                this.$store.dispatch("saveWebKeys", webKeys)
            }

            if (this.$route.query[QUERY_SHOW_PRICE_TYPES]) {
                this.$store.dispatch("setStep", STEP_NAMES.priceTypeSelect)
                return true;
            } else if (this.$route.query[QUERY_PRICE_TYPE]) {
                const selectedPackageType = this.$route.query[QUERY_PRICE_TYPE]
                const priceTypes = await BookingService.GetAsync(this.baseUrl + "/locations/" + this.location.id + "/price-types")

                let defaultPriceType;
                priceTypes.forEach(priceType => {
                    if (priceType.name.toLowerCase() === selectedPackageType.toLowerCase()) {
                        defaultPriceType = priceType;
                    }
                })

                if (defaultPriceType) {
                    this.$store.dispatch("selectPriceType", defaultPriceType)
                }

            } else {
                const priceTypes = await BookingService.GetAsync(this.baseUrl + "/locations/" + this.location.id + "/price-types")

                let defaultPriceType;
                priceTypes.forEach(priceType => {
                    if (priceType.default) {
                        defaultPriceType = priceType;
                    }
                })

                if (defaultPriceType) {
                    this.$store.dispatch("selectPriceType", defaultPriceType)
                }
            }
            return false;
        },
        setUpSource() {
            // set the source is available
            const source = this.$route.query[QUERY_SOURCE]
            if (source) {
                if (source === 'locked' || source === 'escape-world') {
                    this.$store.dispatch("setSource", source)
                }
            }
        },
        setUpMultiSelect() {
            const multiSelect = this.$route.query[QUERY_MULTI_SELECT];
            if (multiSelect) {
                this.$store.dispatch("setStep", STEP_NAMES.roomMultiSelect)
                return true;
            }
            return false;
        },
        setUpRoom() {
            const roomTag = this.$route.query[QUERY_ROOM_TAG];
            if (roomTag) {
                const foundRoom = this.location.rooms.find(r => r.code === roomTag)

                if (foundRoom) {
                    this.$store.dispatch("selectRoom", foundRoom)
                    this.$store.dispatch("setStep", STEP_NAMES.slotSelect)
                    return true;
                }
            }
            return false;
        }
    }
}
</script>

<style scoped>

</style>
