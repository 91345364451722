import moment from "moment-timezone";

const MOMENT_FORMAT = "DD-MM-YYYYhh:mm:ss"

interface Storage<T> {
    exp: string,
    data: T
}

const StorageService = {
    getFromStorage<T>(key: string): T | null {
        if (!this._isLocalStorageEnabled()) {
            return null;
        }

        const rawData = localStorage.getItem(key);
        if (!rawData) {
            return null
        }
        const storageData = (JSON.parse(rawData) as Storage<T>)
        if (moment().isAfter(moment(storageData.exp, MOMENT_FORMAT))) {
            localStorage.removeItem(key);
            return null;
        }
        return storageData.data;
    },

    setInStorage<T>(key: string, data: T, expMinutes: number): void {
        if (!this._isLocalStorageEnabled()) {
            return;
        }
        const storage: Storage<T> = {exp: moment().add(expMinutes, 'minutes').format(MOMENT_FORMAT), data: data};
        localStorage.setItem(key, JSON.stringify(storage));
    },

    resetStorage() {
        if (!this._isLocalStorageEnabled()) {
            return;
        }
        localStorage.clear();
    },

    _isLocalStorageEnabled() {
        try {
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('feature_test', 'yes');
                if (localStorage.getItem('feature_test') === 'yes') {
                    localStorage.removeItem('feature_test');
                    return true
                } else {
                    return false
                }

            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }
};

export default StorageService;
