<template>
    <div class="booking-summary__header">
        <h2 class="booking-summary__header-title">
            {{ translate("overview") }}
        </h2>
    </div>

    <div class="booking-summary__section">
        <div class="booking-summary__order-title">
            <p v-if="order.location">
                {{ order.location.displayName }}
            </p>
        </div>

        <div v-for="orderRow in order.orderRows" v-bind:key="orderRow.seqNr">
            <ul v-if="orderRow.room || orderRow.price">

                <li v-if="orderRow.slot && orderRow.date">
                    <span v-if="orderRow.date">
                        <span>
                            {{ useFormatDay(orderRow.date) }} {{ useFormatDate(orderRow.date) }}
                        </span>
                    </span>
                </li>

                <li v-if="orderRow.slot && orderRow.date">
                    <span v-if="orderRow.slot">
                        {{ orderRow.slot.startHour }}:{{ usePadding(orderRow.slot.startMinute) }} -&nbsp;
                    </span>
                    <span v-if="orderRow.room">
                        {{ orderRow.room.name }}
                    </span>
                </li>

                <li v-if="order.priceType">
                    <span v-if="orderRow.preferredCombinationTime">
                            {{ orderRow.preferredCombinationTime }} -&nbsp;
                    </span>
                    <span>
                         {{ order.priceType.name }}
                     </span>
                </li>

                <li v-if="orderRow.price">
                    <span>{{ orderRow.price.people }} {{ translate("people") }}</span>
                </li>


                <li v-if="orderRow.price">
                    <span class="booking-summary__price">&euro; {{ orderRow.price.amount }} ,-</span>
                </li>

            </ul>
        </div>
    </div>

    <div class="booking-summary__voucher" v-if="order.voucherCode">
        <p>
            {{ order.voucherCode }}
        </p>
        <p v-if="order.voucher.isSecondRoomsHalfPrice && order.orderRows.length !== 2">
            {{ translate("voucher-2-rooms-error") }}
        </p>
        <p v-else-if="order.voucher.isSecondRoomsHalfPrice">
            - &euro; {{ getLowestRoomPrice() / 2 }},-
        </p>
        <p v-else-if="order.voucher.isPercentage">
            - {{ order.voucher.amount }} %
        </p>
        <p v-else>
            - &euro; {{ order.voucher.amount }},-
        </p>
    </div>

    <div class="booking-summary__footer">
        <p>
            {{ translate("total") }}
        </p>
        <p>
            &euro; {{ totalPrice }},-
        </p>
    </div>

</template>

<script>
import moment from "moment-timezone";
import TranslationService from "@/services/TranslationService";
import {PriceService} from "@/services/PriceService";

const priceService = new PriceService()

export default {
    name: "booking-summary-component",
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        order() {
            return this.$store.state.currentOrder
        },
        totalPrice() {
            return priceService.calculateTotal(this.order)
        },
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "summary-component", value)
        },
        useFormatDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        getLowestRoomPrice() {
            return priceService.getLowestRoomPrice(this.order)
        },
        usePadding(num) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().slice(17, 19);
        },
        useFormatDay(date) {
            if (!moment(date).isValid()) {
                return "-"
            }
            return this.translate(moment(date).format("dddd"));
        },
    }
}
</script>

<style scoped>

</style>
