import {OrderModel} from "@/models/order.model";
import _ from "lodash";

export class PriceService {
    getLowestRoomPrice(order: OrderModel) {
        const lowestPrice = _.sortBy(order.orderRows, "price.amount")
        return lowestPrice[0]?.price?.amount;
    }

    calculateTotal(order: OrderModel) {
        let total = 0;
        order.orderRows.forEach((or) => {
            if (or.price) {
                total += or.price.amount;
            }
        });

        if (order.voucher) {
            if (order.voucher.isSecondRoomsHalfPrice && order.orderRows.length !== 2) {
                return total;
            }

            if (order.voucher.isSecondRoomsHalfPrice) {
                return total -= (this.getLowestRoomPrice(order) / 2);
            } else {
                if (order.voucher.isPercentage) {
                    return total - (total * (order.voucher.amount / 100));
                } else {
                    return total - order.voucher.amount;
                }
            }
        }

        return total;
    }

}
