<template>
    <article class="background">
        <section class="pagesection pagesection--first feedback">
            <div class="pagesection__container">
                <div class="grid" v-if="!loading">
                    <div class="logo-center">
                        <img class="logo" src="https://escapeworld.nl/wp-content/uploads/2020/11/escape-worlld-logo.png">
                    </div>

                    <div class="grid__column grid__column--12 feedback_app" v-if="isUnsubscribed">

                        <article class="feedback__body">
                            <section class="feedback__body__unsubscribe">
                                <p>
                                    Je hebt je afgemeld voor feedback berichten
                                </p>
                            </section>
                        </article>
                    </div>

                    <div class="grid__column grid__column--12 feedback_app" v-else>

                        <article class="feedback__body" v-if="orderHasFeedback">

                            <section class="feedback__body__complete">
                                <h3 class="text-center">
                                    Jouw feedback is belangrijk voor ons.<br/>
                                    Dank je wel voor het delen!
                                </h3>
                            </section>
                        </article>

                        <article class="feedback__body" v-else>

                            <section class="feedback__body__scores" v-if="feedback.score === 0">
                                <p>
                                    We zijn altijd bezig om de ervaringen van onze gasten verbeteren.<br/>
                                    Daarom zijn wij erg benieuwd hoe jij je bezoek aan Escape World hebt ervaren.<br/>
                                    Wil je in 2 minuten jouw feedback met ons delen? Dat zou ons ontzettend helpen. <br/>
                                    Mijn ervaring bij Escape World was:
                                </p>

                                <div class="feedback__body__scores__button-container">
                                    <div class="feedback__body__scores__button" @click="setFeedbackScore(1)">
                                        <img height="100" src="../assets/scores/score_1-color.png" alt="slecht">
                                    </div>

                                    <div class="feedback__body__scores__button" @click="setFeedbackScore(2)">
                                        <img height="100" src="../assets/scores/score_2-color.png" alt="matig">
                                    </div>

                                    <div class="feedback__body__scores__button" @click="setFeedbackScore(3)">
                                        <img height="100" src="../assets/scores/score_3-color.png" alt="neutraal">
                                    </div>

                                    <div class="feedback__body__scores__button" @click="setFeedbackScore(4)">
                                        <img height="100" src="../assets/scores/score_4-color.png" alt="leuk">
                                    </div>

                                    <div class="feedback__body__scores__button" @click="setFeedbackScore(5)">
                                        <img height="100" src="../assets/scores/score_5-color.png" alt="superleuk!">
                                    </div>
                                </div>

                            </section>

                            <section class="feedback__body__questions"
                                     v-if="feedback.score === 1 || feedback.score === 2 || feedback.score === 3">
                                <p v-if="feedback.score === 1 || feedback.score === 2">
                                    Dank je wel dat je even tijd wil nemen om je feedback te geven.<br/>
                                    Wij begrijpen dat je bezoek aan Escape World is tegengevallen. <br/>
                                    Dat vinden wij ontzettend jammer.<br/>
                                    <br/>
                                    Wij willen onze gasten een fantastische beleving bieden.<br/>
                                    Wil je ons daarom vertellen wat er is mis gegaan door onderstaande vragen te
                                    beantwoorden? <br/>
                                    Hopelijk kunnen wij er voor zorgen dat je uiteindelijk met een goed gevoel aan Escape
                                    World terug denkt.<br/>
                                    Dank je wel voor je hulp!
                                </p>

                                <p v-if="feedback.score === 3">
                                    Dank je wel dat je even tijd wil nemen om je feedback te geven.<br/>
                                    Wij begrijpen dat je bezoek aan Escape World als neutraal hebt ervaren. <br/>
                                    Dat vinden wij jammer omdat wij aan onze gasten een fantastische beleving willen bieden.<br/>
                                    Je helpt ons ontzettend onze beleving te verbeteren door je feedback te geven op de
                                    volgende vragen.<br/>
                                </p>

                                <div class="feedback__body__question__container">
                                    <label class="feedback__body__questions__label" for="Room">
                                        Wat vond je van de kamer?
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input" id="Room" type="text"
                                              name="Room"
                                              tabindex="100" v-model="feedback.roomFeedback" placeholder="Kamer feedback">
                                </textarea>
                                </div>

                                <div class="feedback__body__question__container">
                                    <label class="feedback__body__questions__label" for="GameMaster">
                                        Wat vond je van de gamemaster?
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input" id="GameMaster"
                                              type="text" name="GameMaster"
                                              tabindex="110" v-model="feedback.gameMasterFeedback"
                                              placeholder="Gamemaster feedback">
                                </textarea>
                                </div>

                                <div class="feedback__body__question__container">
                                    <label class="feedback__body__questions__label" for="WelcomeFeedback">
                                        Wat vond je van de algehele ontvangst?
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input" id="WelcomeFeedback"
                                              type="text" name="WelcomeFeedback"
                                              tabindex="120" v-model="feedback.welcomeFeedback"
                                              placeholder="Ontvangst feedback">
                                </textarea>
                                </div>

                                <div class="feedback__body__question__container"
                                     v-if="feedback.score === 1 || feedback.score === 2">
                                    <label class="feedback__body__questions__label" for="ExpectationFeedback">
                                        Waarom was het bezoek minder dan je verwachtte?
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input"
                                              id="ExpectationFeedback" type="text" name="ExpectationFeedback"
                                              tabindex="130" v-model="feedback.expectationFeedback"
                                              placeholder="Verwachtingen feedback">
                                </textarea>
                                </div>
                                <div class="feedback__body__question__container"
                                     v-if="feedback.score === 1 || feedback.score === 2">
                                    <label class="feedback__body__questions__label" for="ImprovementText1">
                                        Hoe kunnen wij alsnog zorgen dat je alsnog met een goed gevoel<br/>
                                        terugkijkt op je escape room avontuur:
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input" id="ImprovementText1"
                                              type="text" name="ImprovementText1"
                                              tabindex="140" v-model="feedback.improvementText" placeholder="Feedback">
                                </textarea>

                                </div>

                                <div class="feedback__body__question__container" v-if="feedback.score === 3">
                                    <label class="feedback__body__questions__label" for="ImprovementText2">
                                        Wat had er beter gemoeten voor een leukere ervaring?
                                    </label>
                                    <textarea maxlength="500" class="feedback__body__questions__input" id="ImprovementText2"
                                              type="text" name="ImprovementText2"
                                              tabindex="140" v-model="feedback.ImprovementText" placeholder="Feedback">
                                </textarea>

                                </div>

                                <div class="feedback__body__question__container--break">
                                    &nbsp;
                                </div>

                                <div class="feedback__body__questions__submit">
                                    <button class="btn feedback__button" @click="sendFeedback()">
                                        Feedback verzenden
                                    </button>
                                </div>

                            </section>

                        </article>

                        <article class="feedback__body" v-if="feedback.score === 4 || feedback.score === 5">
                            <section class="feedback__body__reviews">
                                <p class="text-center">
                                    Dank je wel dat je even tijd wil nemen om je feedback te geven.<br/>
                                    Zou je op een van onderstaande sites je ervaring willen delen?
                                </p>

                                <div class="columns feedback__body__reviews__image-container" v-if="order">

                                    <a class="col feedback__body__reviews__image" :href="escapeRoomsNlMap">
                                        <img src="../assets/logo/escaperooms-nederland.png" alt="escaperooms-nederland"/>
                                    </a>

                                    <a class="col feedback__body__reviews__image" :href="tripAdvisorMap">
                                        <img src="../assets/logo/tripadvisor.png" alt="tripadvisor"/>
                                    </a>

                                    <a class="col feedback__body__reviews__image" :href="escapeTalkMap">
                                        <img src="../assets/logo/escape-talk.jpg" alt="escape-talk"/>
                                    </a>

                                </div>

                            </section>
                        </article>


                    </div>

                </div>

                <div class="grid" v-else>
                    <div class="logo-center">
                        <img class="logo" src="https://escapeworld.nl/wp-content/uploads/2020/11/escape-worlld-logo.png">
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script>
import BookingService from "@/services/BookingService";

export default {
    name: "FeedbackAppView",
    data: () => {
        return {
            baseUrl: "/api/v1/booking",
            isUnsubscribed: false,
            orderHasFeedback: false,
            orderKey: "",
            order: null,
            errorMessage: null,
            feedback: {
                score: 0,
                roomFeedback: "",
                gameMasterFeedback: "",
                welcomeFeedback: "",
                expectationFeedback: "",
                improvementText: "",
            },
            loading: true
        }
    },
    async created() {
        this.orderKey = this.$route.params["orderKey"]
        this.isUnsubscribed = this.$route.query["unsubscribe"]

        if (this.$route.params["score"]) {
            this.feedback.score = parseInt(this.$route.params["score"])
        }

        this.order = await BookingService.GetAsync(`${this.baseUrl}/orders/${this.orderKey}/feedback`)
        this.orderHasFeedback = this.order.hasFeedback;
        await this.setFeedbackScore(this.feedback.score);

        if (this.isUnsubscribed) {
            await this.unsubscribe()
        }

        this.loading = false;
    },
    computed: {
        tripAdvisorMap() {
            const map = {
                1: "https://www.tripadvisor.nl/UserReviewEdit-g188590-d12159675-Escape_World_Waterlooplein-Amsterdam_North_Holland_Province.html",
                2: "https://www.tripadvisor.nl/UserReviewEdit-g227906-d11611802-Escape_World_Hoofddorp-Hoofddorp_Haarlemmermeer_North_Holland_Province.html",
                3: "https://www.tripadvisor.nl/UserReviewEdit-g227913-d13478818-Escape_World_Zoetermeer-Zoetermeer_South_Holland_Province.html",
                4: "https://www.tripadvisor.nl/UserReviewEdit-g188586-d8510579-Locked-Tilburg_North_Brabant_Province.html"
            };
            return map[this.order.locationId]
        },
        escapeRoomsNlMap() {
            const map = {
                1: "https://www.escaperoomsnederland.nl/rooms/escape-world-waterlooplein/",
                2: "https://www.escaperoomsnederland.nl/rooms/escape-world-hoofddorp/",
                3: "https://www.escaperoomsnederland.nl/rooms/escaperooms-dekker-zoetermeer/",
                4: "https://www.escaperoomsnederland.nl/rooms/locked-tilburg-centrum/"
            };
            console.log(map)
            console.log(map[this.order.locationId])
            console.log(map[this.order])
            return map[this.order.locationId]
        },
        escapeTalkMap() {
            const map = {
                1: "https://escapetalk.nl/escaperoom/escape-world-waterloopplein/",
                2: "https://escapetalk.nl/escaperoom/escape-world-hoofddorp/",
                3: "https://escapetalk.nl/escaperoom/escape-world-zoetermeer/",
                4: "https://escapetalk.nl/escaperoom/locked/"
            };
            return map[this.order.locationId]
        }
    },
    methods: {
        async setFeedbackScore(score) {
            this.feedback.score = score
            if (score > 3) {
                await this.sendFeedback()
            }
        },
        async unsubscribe() {
            await BookingService.PostAsync(`${this.baseUrl}/orders/${this.orderKey}/unsubscribe`, null);
            this.isUnsubscribed = true;
        },
        async sendFeedback(score) {
            this.loading = true;
            if (score) {
                this.feedback.score = score;
            }

            try {
                await BookingService.PostAsync(`${this.baseUrl}/orders/${this.orderKey}/feedback`, this.feedback)
                this.orderHasFeedback = true;
                this.loading = false;
            } catch (err) {
                this.errorMessage = "Er ging iets mis met het verzenden van de gegevens, probeer later nogmaals."
                this.orderHasFeedback = true;
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">

.background {
    background-image: url("../assets/background/background_light.jpg");
    width: 100vw;
    min-height: 100vh;
    background-repeat: repeat;
}

.pagesection--first {
    padding-top: 80px
}

.logo-center {
    width: 100%;
    .logo {
        display: block;
        margin: 0 auto;
        width: 300px;
    }
}
</style>
