<template>
    <div class="booking-funnel-step booking-funnel-step--01" v-if="!loading">

        <div v-if="errorMessage" class="booking-funnel-multi-select__error">
            {{ errorMessage }}
        </div>

        <div class="booking-funnel-multi-select__time-container">
            <span class="booking-funnel-step-02__datepicker booking-funnel-step-02__datepicker-btnprev"
                  @click="getPrevDate()">
                <span class="icon"></span>
            </span>

            <div class="booking-funnel-step-02__datepicker-date">
                {{ useFormatDay(selectedDate) }}
            </div>

            <input :disabled="order.orderRows.length > 0" class="booking-funnel-multi-select__time-container__input"
                   v-model="selectedDate" type="date"
                   @change="selectDateDebounced">

            <span class="booking-funnel-step-02__datepicker booking-funnel-step-02__datepicker-btnnext"
                  @click="getNextDate()">
                <span class="icon"></span>
            </span>
        </div>


        <div class="text-center booking-funnel-multi-select__rooms-container" v-if="locationIsClosed">
            <p class="text-center booking-funnel-multi-select__error" style="margin: 10px auto 0;"
               v-if="!order.priceType || dateInPast">
                {{translate("not-open-error")}}
            </p>
            <p class="text-center booking-funnel-multi-select__error" style="margin: 10px auto 0;" v-else>
            <span v-if="order.priceType.minDaysInAdvance > 0">
                {{translate("min-days-error").replace("__minDaysInAdvance__", order.priceType.minDaysInAdvance)}}
            </span>
            <span v-else>
                {{translate("no-times-found").replace("__selectedDate__", useFormatDate(selectedDate))}}
            </span>
            </p>
        </div>

        <div class="booking-funnel-multi-select__rooms-container" v-else>

            <div v-for="calendarObject in orderRooms(locationOverview.roomList)"
                 class="booking-funnel-multi-select__rooms"
                 v-bind:key="calendarObject.room.name">
                <h3>
                    {{ calendarObject.room.name }}
                </h3>
                <ul class="booking-funnel-multi-select__list">
                    <li class="booking-funnel-multi-select__list-item"
                        v-for="slot in orderSlots(calendarObject.slots)"
                        v-bind:key="slot.Id"
                        @click="selectSlot(slot, calendarObject.room)"
                        :class="{
                            'is-inactive' : !isSlotAvailable(slot),
                            'is-active' : slot.isSelected
                        }">
                        {{ slot.startHour }}:{{ usePadding(slot.startMinute) }}
                    </li>
                    <li v-if="calendarObject.slots.length == 0"
                        class="booking-funnel-multi-select__list-item">
                        -
                    </li>
                </ul>
            </div>
        </div>


    </div>

    <div class="booking-funnel-step booking-funnel-step--01" v-else>
        <div class="text-center">
            {{translate("loading")}}
        </div>
    </div>

</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import BookingService from "@/services/BookingService";
import TranslationService from "@/services/TranslationService";

export default {
    name: "booking-multi-room-select",
    data() {
        return {
            baseUrl: "/api/v1/booking",
            errorMessage: null,
            locationOverview: null,
            locationIsClosed: false,
            loading: true,
            selectedDate: moment().tz("Europe/Amsterdam").format("YYYY-MM-DD"),
        }
    },
    async created() {
        if (this.order.orderRows[0]) {
            this.selectedDate = moment(this.order.orderRows[0].date).tz("Europe/Amsterdam").format("YYYY-MM-DD")
        }

        await this.selectDate();

        this.selectDateDebounced = _.debounce(() => {
            this.selectDate()
        }, 500);
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        order() {
            return this.$store.state.currentOrder;
        },
        dateInPast() {
            return moment(this.selectedDate).isBefore(moment().tz("Europe/Amsterdam").subtract(1, 'd'));
        },
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "multi-room-select", value)
        },
        useFormatDate(date) {
            return moment(date).tz("Europe/Amsterdam").format("DD-MM-YYYY")
        },
        usePadding(num) {
            const date = new Date(0);
            date.setSeconds(num);
            return date.toISOString().slice(17, 19);
        },
        useFormatDay(date) {
            if (!moment(date).tz("Europe/Amsterdam").isValid()) {
                return "-"
            }
            return this.translate(moment(date).tz("Europe/Amsterdam").format("dddd"));
        },
        orderRooms(roomList) {
            return _.sortBy(roomList, "seqNr");
        },
        orderSlots(slotList) {
            return _.sortBy(slotList, "startHour")
        },
        isSlotAvailable(slot) {
            if (!slot.isAvailable) {
                return false;
            }

            if (this.order.priceType && !this.order.priceType.default && this.order.orderRows[0]) {
                const selectedSlot = this.order.orderRows[0].slot;

                // check for slots on the same row
                // if it's not a default price Type
                if (selectedSlot
                    && selectedSlot.startHour === slot.startHour
                    && selectedSlot.startMinute === slot.startMinute) {
                    return true;
                }

                return false;
            }
            return true;
        },
        selectDateDebounced() {
            // no-op
        },
        getNextDate() {
            if (!this.checkIfDateChangePossible()) {
                return;
            }
            this.selectedDate = moment(this.selectedDate).tz("Europe/Amsterdam").add(1, 'd').format("YYYY-MM-DD");
            this.selectDate();
        },
        getPrevDate() {
            if (!this.checkIfDateChangePossible()) {
                return;
            }

            this.selectedDate = moment(this.selectedDate).tz("Europe/Amsterdam").add(-1, 'd').format("YYYY-MM-DD");
            this.selectDate();
        },
        async selectDate() {
            this.loading = true;
            this.locationIsClosed = false;
            if (!this.checkIfDateChangePossible()) {
                return;
            }

            let url = this.baseUrl + "/locations/" + this.$route.params["locationName"] + "/overview?date=" + moment(this.selectedDate).tz("Europe/Amsterdam").format("YYYY-MM-DD");

            if (this.order.priceType) {
                url += "&PriceTypeId=" + this.order.priceType.id;
            }

            this.locationOverview = await BookingService.GetAsync(url);
            if (!this.locationOverview.roomList.find(l => l.slots.length > 0)) {
                this.locationIsClosed = true;
            }

            this.setSelectedSlots();

            this.loading = false;
        },
        setSelectedSlots() {
            this.locationOverview.roomList.forEach((room) => {
                room.slots.forEach((slot) => {
                    this.order.orderRows.forEach((orderRow) => {
                        if (orderRow.date && orderRow.slot.id === slot.id) {
                            slot.isSelected = true;
                        }
                    });
                })
            });
        },
        checkIfDateChangePossible() {
            this.errorMessage = null;
            if (this.locationOverview) {
                let found = false;

                this.locationOverview.roomList.forEach((room) => {
                    room.slots.forEach((slot) => {
                        if (slot.isSelected) {
                            found = true;
                        }
                    })
                });

                if (found) {
                    this.errorMessage = this.translate("multiple-days-error");

                    setTimeout(() => {
                        this.errorMessage = null;
                    }, 5000);

                    return false;
                }
            }

            return true;
        },
        selectSlot(slot, room) {
            if (slot.isSelected) {
                const index = this.order.orderRows.findIndex(row => row.slot.id === slot.id)
                this.$store.dispatch("removeOrderRowAtIndex", index);
                slot.isSelected = false;
                return;
            }

            if (!this.isSlotAvailable(slot)) {
                return;
            }

            const orderRow = {
                slot: slot,
                room: room,
                date: this.selectedDate
            };

            this.$store.dispatch("addOrderRow", orderRow);
            slot.isSelected = true;
        }
    }
}
</script>

<style scoped>

</style>
