<template>
    <div class="booking-funnel-step booking-funnel-step--04">

        <p class="booking-funnel-step-04__title">Vul <span>hier</span> je gegevens in</p>

        <div class="booking-funnel-step-04__profile" v-if="!loading">

            <div class="booking-funnel-step-04__form">

                <div class="booking-funnel-step-04__form-container">
                    <fieldset class="booking-funnel-step-04__form-section">
                        <div class="booking-funnel-step-04__form-item">
                            <label for="FirstName">
                                <span>{{ translate("first-name") }}</span>
                            </label>
                            <input id="FirstName" type="text" name="FirstName" tabindex="100" v-model="user.firstName"
                                   :placeholder="translate('first-name')">
                        </div>

                        <div class="booking-funnel-step-04__form-item">
                            <label for="LastName">
                                <span>{{ translate("last-name") }}</span>
                            </label>
                            <input id="LastName" type="text" name="LastName" tabindex="101" v-model="user.lastName"
                                   :placeholder="translate('last-name')">
                        </div>

                        <div class="booking-funnel-step-04__form-item">
                            <label for="Email">
                                <span>{{ translate("email") }}</span>
                            </label>
                            <input id="Email" type="email" name="Email" tabindex="103" v-model="user.email"
                                   :placeholder="translate('email')">
                        </div>

                    </fieldset>

                    <fieldset class="booking-funnel-step-04__form-section">
                        <div class="booking-funnel-step-04__form-item">
                            <label for="Tel">
                                <span>{{ translate("phone") }}</span>
                            </label>
                            <input id="Tel" type="text" name="Tel" tabindex="104" v-model="user.telephoneNumber"
                                   :placeholder="translate('phone')">
                        </div>

                        <div class="booking-funnel-step-04__form-item">
                            <label for="voucher">
                                <span>{{ translate("voucher") }}</span>
                            </label>
                            <input id="voucher" type="text" name="voucher" tabindex="105" v-model="voucherCode"
                                   :placeholder="translate('voucher-placeholder')">
                        </div>

                        <div class="booking-funnel-step-04__form-item booking-funnel-step-04__form-item--voucher">
                            <a href="javascript:;" @click="verifyVoucher()"
                               class="text-center booking-funnel-step-04__btn-voucher">
                                {{ translate("check-voucher") }}
                            </a>

                            <label class="booking-funnel-step__error" v-if="showVoucherError">
                                {{ translate("voucher-not-valid") }}
                            </label>

                            <label class="booking-funnel-step__success" v-if="isVoucherValid">
                                {{ translate("voucher-is-valid") }}
                            </label>
                        </div>

                    </fieldset>

                </div>

                <div class="booking-funnel-step-04__form-container">
                    <fieldset class="booking-funnel-step-04__form-section" v-if="!isSecretLegacy()">

                        <div class="booking-funnel-step-04__form-item booking-funnel-step-04__book-extra-room">
                            <a class="booking-funnel-step-04__btn text-center" href="javascript:;"
                               @click="bookExtraRoom()" tabindex="7">
                                {{ translate("book-extra-room") }}
                            </a>
                        </div>

                        <div class="booking-funnel-step-04__form-item booking-funnel-step-04__form-item--checkbox">
                            <input type="checkbox" id="checkbox" name="accepted" tabindex="200"
                                   v-model="user.acceptPolicy"/>
                            <label for="checkbox" class="checkbox-label"></label>
                            <label for="checkbox">
                                {{ translate("terms-check") }}
                                <a href="https://escapeworld.nl/voorwaarden/" tabindex="6" target="_blank">
                                    {{ translate("terms") }}
                                </a>
                            </label>
                        </div>

                        <div class="booking-funnel-step-04__form-item booking-funnel-step-04__form-item--checkbox">
                            <input type="checkbox" id="is-invoice-requested" name="accepted" tabindex="300"
                                   v-model="isInvoiceRequested"/>
                            <label for="is-invoice-requested" class="checkbox-label"></label>
                            <label for="is-invoice-requested">
                                {{ translate("is-invoice-requested") }}
                            </label>
                        </div>


                    </fieldset>
                </div>

            </div>
        </div>

        <div>
            <fieldset class="booking-funnel-step-04__form-section">

            </fieldset>
        </div>

        <div v-if="isInvoiceRequested" class="booking-funnel-step-04__invoice-container">
            <fieldset class="booking-funnel-step-04__invoice-section">

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-contact">
                        <span>{{ translate("invoice-company-name") }}*</span>
                    </label>
                    <input id="invoice-company-name" type="text" name="invoiceCompanyName" tabindex="310"
                           v-model="invoiceRequest.companyName"
                           :placeholder="translate('invoice-company-name')">
                </div>

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-contact">
                        <span>{{ translate("invoice-company-email") }}</span>
                    </label>
                    <input id="invoice-company-email" type="text" name="invoiceCompanyEmail" tabindex="320"
                           v-model="invoiceRequest.companyEmail"
                           :placeholder="translate('invoice-company-email')">
                </div>

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-is-company" style="display: inline-block">
                        <span>{{ translate("invoice-is-company") }}</span>
                    </label>
                    <input id="invoice-is-company" type="checkbox" name="invoiceIsCompany" tabindex="330"
                           v-model="invoiceRequest.isCompany">
                </div>

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-address">
                        <span>{{ translate("invoice-address") }}*</span>
                    </label>
                    <input id="invoice-address" type="text" name="invoiceAddress" tabindex="340"
                           v-model="invoiceRequest.address"
                           :placeholder="translate('invoice-address')">
                </div>

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-address">
                        <span>{{ translate("invoice-postal-code") }}*</span>
                    </label>
                    <input id="invoice-postal-code" type="text" name="invoicePostalCode" tabindex="350"
                           v-model="invoiceRequest.postalCode"
                           :placeholder="translate('invoice-postal-code')">
                </div>

                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-address">
                        <span>{{ translate("invoice-city") }}*</span>
                    </label>
                    <input id="invoice-city" type="text" name="invoiceCity" tabindex="360"
                           v-model="invoiceRequest.city"
                           :placeholder="translate('invoice-city')">
                </div>


                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-address">
                        <span>{{ translate("invoice-country") }}*</span>
                    </label>

                    <select id="invoice_country" class="booking-funnel-step-04__form-item__select"
                            v-model="invoiceRequest.country">
                        <option v-for="country in countryList" :value="country.value" :key="country.value">
                            {{ country.label }}
                        </option>
                    </select>
                </div>

            </fieldset>

            <fieldset class="booking-funnel-step-04__invoice-section">
                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-contact">
                        <span>{{ translate("invoice-contact") }}</span>
                    </label>
                    <input id="invoice-contact" type="text" name="invoiceContact" tabindex="310"
                           v-model="invoiceRequest.invoiceContact"
                           :placeholder="translate('invoice-contact')">
                </div>
                <div class="booking-funnel-step-04__form-item">
                    <label for="invoice-contact">
                        <span>{{ translate("invoice-reference") }}</span>
                    </label>
                    <input id="invoice-reference" type="text" name="invoiceReference" tabindex="310"
                           v-model="invoiceRequest.costLocation"
                           :placeholder="translate('invoice-reference')">
                </div>

            </fieldset>
        </div>

        <div class="booking-funnel-step-04__payment">

            <div class="text-danger" style="color:red; font-size: 26px;" v-if="errorMessage">
                {{ errorMessage }}
            </div>


            <div class="text-danger" style="color:red; font-size: 26px;" v-if="paymentErrorMessage">
                <p>Your booking has to be manually. Please contact info@escapeword.nl. Sorry for the inconvenience.</p>
                <br>
                {{ paymentErrorMessage }}
            </div>

            <div class="text-danger" style="color:red; font-size: 26px;" v-if="extraInformation">
                {{ translate("more-info") }} {{ extraInformation }}
            </div>

            <h3>
                {{ translate("payment-method") }}
            </h3>

            <ul class="booking-funnel-step-04__list">
                <li class="booking-funnel-step-04__list-item" @click="savePaymentMethod('ideal')"
                    :class="{'is-selected' : order.paymentType ===  'ideal'}">
                    <img src="../assets/logo//logo-ideal.svg" alt="Ideal Betalen">
                </li>
                <li class="booking-funnel-step-04__list-item" v-if="location.id !== 4"
                    @click="savePaymentMethod('paypal')" :class="{'is-selected' : order.paymentType ===  'paypal'}">
                    <img src="../assets/logo//logo-paypal.svg" alt="paypal">
                </li>
                <li class="booking-funnel-step-04__list-item" @click="savePaymentMethod('creditcard')"
                    :class="{'is-selected' : order.paymentType ===  'creditcard'}">
                    <img src="../assets/logo/logo-credit-card.svg" alt="Creditcard">
                </li>
                <li style="background-color: #33ff00;" class="booking-funnel-step-04__list-item"
                    :class="{'is-selected' : order.paymentType ===  'pay-on-location'}"
                    v-if="location.id === 4" @click="savePaymentMethod('pay-on-location')">
                    {{ translate("pay-at-location") }}
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
import {STEP_NAMES} from "@/store";
import BookingService from "@/services/BookingService";
import TranslationService from "@/services/TranslationService";

import moment from "moment-timezone";
import countries from "i18n-iso-countries";
import i18n_iso_countries from "i18n-iso-countries/langs/en.json";

countries.registerLocale(i18n_iso_countries)

export default {
    name: "booking-customer-select",
    data() {
        return {
            baseUrl: "/api/v1/booking",
            loading: false,
            voucherCode: null,
            showVoucherError: false,
            errorMessage: null,
            extraInformation: null,
            isInvoiceRequested: false,
            isVoucherValid: false,
            invoiceRequest: {
                isCompany: false,
                invoiceContact: "",
                costLocation: "",
                companyName: "",
                companyEmail: "",
                address: "",
                postalCode: "",
                city: "",
                country: "NL",
            },
            user: {
                firstName: "",
                lastName: "",
                email: "",
                telephoneNumber: "",
                acceptPolicy: false,
                acceptCovidPolicy: true
            },
        }
    },
    computed: {
        language() {
            return this.$store.state.currentLang
        },
        location() {
            return this.$store.state.location
        },
        order() {
            return this.$store.state.currentOrder
        },
        paymentErrorMessage() {
            return this.$store.state.paymentError
        },
        countryList() {
            const list = countries.getNames('en', {select: 'official'})
            return Object.keys(list).map((key) => ({value: key, label: list[key]}))
        }
    },
    created() {
        if (this.$store.state.user) {
            this.user = this.$store.state.user
        }
    },
    methods: {
        translate(value) {
            return TranslationService.translate(this.language, "customer-select", value)
        },
        validateEmail(email) {
            // eslint-disable-next-line
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        isSecretLegacy() {
            // no op ??
        },
        bookExtraRoom() {
            this.$store.dispatch("setStep", STEP_NAMES.roomMultiSelect)
        },
        async verifyVoucher() {
            this.showVoucherError = false;
            this.errorMessage = null;
            this.isVoucherValid = false;

            const voucherVerifyRequest = {
                locationId: this.order.location.id,
                VoucherCode: this.voucherCode,
                RoomIds: [],
                BookingDates: []
            };

            this.order.orderRows.forEach((row) => {
                voucherVerifyRequest.RoomIds.push(row.room.id);
                voucherVerifyRequest.BookingDates.push(moment(row.date).format("YYYY-MM-DD"));
            });

            try {
                const voucher = await BookingService.PostAsync(`${this.baseUrl}/vouchers/verify`, voucherVerifyRequest)
                voucher.voucherCode = this.voucherCode;
                this.$store.commit("setVoucher", voucher)
                this.isVoucherValid = true
            } catch (e) {
                this.showVoucherError = true
                this.errorMessage = this.translate("voucher-not-verified")
            }
        },

        isFormValid() {
            this.errorMessage = null;
            if (!this.user.acceptPolicy) {
                this.errorMessage = this.translate("validation-accept-terms");
                return;
            }

            if (this.voucherCode && !this.order.voucherCode) {
                this.errorMessage = this.translate("validation-check-voucher")
                return false;
            }

            if (this.isInvoiceRequested && (
                !this.invoiceRequest.companyName
                || !this.invoiceRequest.address
                || !this.invoiceRequest.postalCode
                || !this.invoiceRequest.city
                || !this.invoiceRequest.country
            )) {
                this.errorMessage = this.translate("validation-check-invoice")
                return false;
            }

            if (this.invoiceRequest.companyEmail !== '' && !this.validateEmail(this.invoiceRequest.companyEmail)) {
                this.errorMessage = this.translate("validation-email-address")
                return false;
            }

            if (!this.user.firstName
                || !this.user.lastName
                || !this.user.email
                || !this.user.telephoneNumber) {
                this.errorMessage = this.translate("validation-error")

                return false;
            }

            if (!this.validateEmail(this.user.email)) {
                this.errorMessage = this.translate("validation-email")
                return false;
            }
            return true;
        },
        savePaymentMethod(paymentMethod) {
            if (!this.isFormValid()) {
                return;
            }

            this.$store.dispatch("setUser", this.user);
            if (this.isInvoiceRequested) {
                this.$store.dispatch("setInvoiceInformation", this.invoiceRequest);
            } else {
                this.$store.dispatch("removeInvoiceInformation");
            }
            this.$store.dispatch("setPaymentMethod", paymentMethod);
        },
    }
}

</script>

<style scoped>
.is-selected {
    background-color: #edb51e;
}

.booking-funnel-step-04__list-item:hover {
    cursor: pointer;
}
</style>
